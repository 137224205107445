import { useState } from "react";
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown, ChevronRight } from "@mui/icons-material";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuAnchor(null);
    setSubmenuOpen(false);
  };

  const handleSubmenuOpen = (event) => {
    setSubmenuAnchor(event.currentTarget);
    setSubmenuOpen(true);
  };

  const handleSubmenuClose = () => {
    setSubmenuOpen(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          MyBrand
        </Typography>

        {/* Horizontal Menu Items */}
        <Button color="inherit">Home</Button>

        {/* Services with Mega Menu */}
        <Button
          color="inherit"
          onMouseEnter={handleMenuOpen}
          onMouseLeave={handleMenuClose}
          endIcon={<ArrowDropDown />}
        >
          Services
        </Button>

        {/* Mega Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{ onMouseLeave: handleMenuClose }}
        >
          <MenuItem onMouseEnter={handleSubmenuOpen} onMouseLeave={handleSubmenuClose}>
            Design <ChevronRight />
          </MenuItem>
          <MenuItem>Development</MenuItem>
          <MenuItem>Marketing</MenuItem>
        </Menu>

        {/* Submenu for Design */}
        <Menu
          anchorEl={submenuAnchor}
          open={submenuOpen}
          onClose={handleSubmenuClose}
          MenuListProps={{ onMouseLeave: handleSubmenuClose }}
        >
          <MenuItem>UI/UX Design</MenuItem>
          <MenuItem>Graphic Design</MenuItem>
        </Menu>

        <Button color="inherit">Products</Button>
        <Button color="inherit">Contact</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;