import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

const handleAlertInfoOpen = (info) => {
  // Your alert info handler logic here
  console.log("Info clicked:", info);
};

const handleStatusChange = (row) => {
  // Your status change logic here
  console.log("Status change for row:", row);
};

const handleTeamsAlertClick = () => {
  // Your alert click logic here
  console.log("Teams alert clicked");
};

const handleWebhookStatusChange = (row) => {
  // Your webhook status change logic here
  console.log("Webhook status change for row:", row);
};

const staticRows = [
  {
    id: 1,
    monitor_name: 'CPU Usage Alert',
    monitor_severity: 'High',
    status: true,
    alert_info: 'CPU Usage exceeds 80%',
    monitor_status: true,
    webhook_status: true,
  },
  {
    id: 2,
    monitor_name: 'Memory Usage Alert',
    monitor_severity: 'Medium',
    status: false,
    alert_info: 'Memory usage exceeds 70%',
    monitor_status: false,
    webhook_status: false,
  },
  // Add more rows as needed
];

const monitorcolumns = [
  { field: 'monitor_name', headerName: 'Ruleset Name', flex: 0.9 },
  { field: 'monitor_severity', headerName: 'Severity', flex: 0.2 },
  { field: 'status', headerName: 'Ruleset Status', flex: 0.4, renderCell: (params) => (
      params.value ? <span style={{ color: 'green' }}>Enabled</span> : <span style={{ color: 'red' }}>Disabled</span>
    ),
  },
  { field: 'alert_info', headerName: 'Info', flex: 0.3, renderCell: (params) => (
      <Info onClick={() => handleAlertInfoOpen(params.value)} size={30} style={{ cursor: 'pointer', color: 'blue' }} />
    ),
  },
  { field: 'monitor_status', headerName: 'Action', flex: 0.4, renderCell: (params) => (
      params.value ? (
        <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="error">Disable</Button>
      ) : (
        <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="primary">Enable</Button>
      )
    ),
  },
  { field: 'webhook_status', headerName: 'Webhook', flex: 0.4, renderCell: (params) => (
      params.value ? (
        <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="outlined" color="error">Deactivate</Button>
      ) : (
        <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="outlined" color="primary">Activate</Button>
      )
    ),
  },
];

const AlertConfiguration = () => {
  return (
    <div style={{ height: '75vh', width: '100%', marginTop: '2%' }}>
        <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <DataGrid
        rows={staticRows}
        columns={monitorcolumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
};

export default AlertConfiguration;