import React, { useState } from 'react';
import { 
  TextField, Button, Checkbox, FormControlLabel, Typography, Link, Grid, FormHelperText 
} from '@mui/material';
import axios from 'axios';
import wave1 from '../images/wave_latest.svg';
import signupImage from '../images/login (1).svg';
import logo from '../images/khikalogo.png';
import { BaseUrl } from '../constants/constants';
import useFullPageLoader from './hooks/useFullPageLoader';

const NewSignUp = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const validatePassword = () => {
    const strongPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const easyPattern = /^(.)\1{7,}$|1234|abcd|qwerty|password|welcome|letmein/i;

    if (!strongPasswordPattern.test(password)) {
      setPasswordError(
        "Password must contain at least 8 characters, including uppercase, lowercase, a digit, and a special character."
      );
      return false;
    }

    if (easyPattern.test(password)) {
      setPasswordError("Your password is too simple. Please create a more secure password.");
      return false;
    }

    setPasswordError('');
    return true;
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return false;
    }
    setConfirmPasswordError('');
    return true;
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!validatePassword() || !validateConfirmPassword()) {
      return;
    }

    showLoader();

    const data = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email_address: email,
      password: password,
      confirm_password: confirmPassword,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/auth/signup',
      headers: { 'Content-Type': 'application/json' },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        hideLoader();
        if (response.status === 200) {
          window.location.href = '/login';
        }
      })
      .catch((error) => {
        hideLoader();
        alert('Error: ' + error.response.data.message);
      });
  };

  return (
    <div>
      <img
        className="wave"
        src={wave1}
        alt="Wave Background"
        style={{ position: 'absolute', width: '100%', top: '0' }}
      />
      <div className="container-login" style={{ marginTop: '40px' }}>
        <div className="img">
          <img className="login-image" src={signupImage} alt="Sign Up Illustration" />
        </div>

        <div className="login-content">
          <form className="form-class-login" onSubmit={handleSignUp}>
            <div className="row" style={{ textAlign: 'center' }}>
              <img className="actionate-img" src={logo} width="50%" alt="Logo" />
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={validatePassword}
                />
                {passwordError && <FormHelperText error>{passwordError}</FormHelperText>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={validateConfirmPassword}
                />
                {confirmPasswordError && <FormHelperText error>{confirmPasswordError}</FormHelperText>}
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />
              }
              label={
                <Typography variant="body2">
                  By signing up, you agree to the{' '}
                  <Link href="/Khika-Privacy-Policy.pdf" underline="always" target="_blank">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link href="/Khika-License-Terms-Conditions 1.pdf" underline="always" target="_blank">
                    License Terms & Conditions
                  </Link>.
                </Typography>
              }
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
              disabled={!checked}
              type="submit"
            >
              Sign Up
            </Button>

            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <Link href="/login" variant="body2">
                Already have an account? Login
              </Link>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default NewSignUp;
