import React from "react";
import { AppBar, Toolbar, Typography, Box, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { ExpandMore, Dashboard, BarChart, CalendarMonth, Timeline, DateRange } from "@mui/icons-material";

const Navbar = () => {
  return (
    <AppBar position="static" sx={{ background: "#1e1e2f", padding: "8px 0" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
          🚀 My Stylish Navbar
        </Typography>

        <Box sx={{ display: "flex", gap: 3 }}>
          {/* Main Menu */}
          <Box className="menu">
            <Typography className="menu-item">
              Main Menu <ExpandMore />
              {/* Dropdown Menu */}
              <Box className="menu-content">
                <MenuItem component={Link} to="/dashboard">
                  <Dashboard fontSize="small" sx={{ marginRight: 1 }} /> Dashboard
                </MenuItem>

                {/* Reports Submenu */}
                <Box className="submenu">
                  <MenuItem className="submenu-item">
                    <BarChart fontSize="small" sx={{ marginRight: 1 }} />
                    Reports <ExpandMore />
                    {/* Reports Submenu Content */}
                    <Box className="submenu-content">
                      <MenuItem component={Link} to="/reports/daily">📅 Daily Reports</MenuItem>
                      <MenuItem component={Link} to="/reports/weekly">
                        <Timeline fontSize="small" sx={{ marginRight: 1 }} /> Weekly Reports
                      </MenuItem>

                      {/* Monthly Reports Submenu */}
                      <Box className="nested-submenu">
                        <MenuItem className="nested-submenu-item">
                          <CalendarMonth fontSize="small" sx={{ marginRight: 1 }} />
                          Monthly Reports <ExpandMore />
                          {/* Nested Submenu Content */}
                          <Box className="nested-submenu-content">
                            <MenuItem component={Link} to="/reports/monthly/january">📆 January</MenuItem>
                            <MenuItem component={Link} to="/reports/monthly/february">📆 February</MenuItem>
                          </Box>
                        </MenuItem>
                      </Box>

                      <MenuItem component={Link} to="/reports/yearly">
                        <DateRange fontSize="small" sx={{ marginRight: 1 }} /> Yearly Reports
                      </MenuItem>
                    </Box>
                  </MenuItem>
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;