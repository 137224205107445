import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover } from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings'; 
import PersonIcon from '@mui/icons-material/Person'; 
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; 
import HelpIcon from '@mui/icons-material/Help'; 



const MENU_OPTIONS = [
  {
    label: 'Profile',
    link: '/dashboard/profile',
    icon: <PersonIcon sx={{ mr: 1 }} />, 
  },
  // {
  //   label: 'Help & Support',
  //   link: '/help-and-support', 
  //   icon: <HelpIcon sx={{ mr: 1 }} />, 
  // },
];

export default function AccountPopover(props) {
  let user = props.UserData;
  const [open, setOpen] = useState(null);
  const user_details = JSON.parse(window.localStorage.getItem("user_details"))['data'];

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleOpen}
        sx={{
          cursor: 'pointer',
          color: 'primary.main', 
        }}
      >
        <IconButton sx={{ p: 0, mr: 1 }}>
          <SettingsIcon />
        </IconButton>
        <Typography variant="body1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          Welcome, {user?.first_name || 'User'}
        </Typography>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 220,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option, index) => (
            <>
              <MenuItem key={option.label} component="a" href={option.link}>
                {option.icon} 
                {option.label}
              </MenuItem>
              {index === 0 && <Divider sx={{ borderStyle: 'dashed' }} />} 
            </>
          ))}
        </Stack>

        <MenuItem sx={{ m: 1 }} component="a" href="/logout">
          <ExitToAppIcon sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
