import React, { useState } from 'react'
import axios, * as others from 'axios';
import { Card, Box, Button, Typography, Tooltip, Modal, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Toggle from './Toggle';
import { BaseUrl } from '../constants/constants';

const ActiveReponse = () => {

  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleOpenModal = (record) => {
    setSelectedRecord(record);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      field: 'timestamp',
      headerName: 'Date Time',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      )
    },
    {
      field: 'agent_name',
      headerName: 'Agent Name',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      )
    },
    {
      field: 'ruleid',
      headerName: 'Rule Id',
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      )
    },
    {
      field: 'level',
      headerName: 'Level',
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained"
        size="small"
        sx={{
          padding: "2px 8px",
          fontSize: "12px",
          fontWeight: 500,
          borderRadius: "4px",
          textTransform: "none",
          minWidth: "80px",
        }}
        onClick={() => handleOpenModal(params.row)}>
          View Details
        </Button>
      )
    }
  ];

  const tenant_access_switched = localStorage.getItem("tenant_access_switched");

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = tenant_access_switched ? tenant_access_switched : user_details.data.accessToken;

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);

  const now = new Date().toISOString();

  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  React.useEffect(() => {
    fetchData('');

  }, []);

  const [filterjson, setFilterJson] = useState(
    {
      "size": 1000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {

        "bool": {
          "filter": [
            { "term": { "decoder.name": "ar_log_json" } },
            {
              "range": {
                "timestamp": {
                  "gte": startdate,
                  "lt": enddate
                }
              }
            }
          ]
        }
      }
    }
  );

  const fetchData = async page => {
    setLoading(true);
    var data = JSON.stringify(filterjson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts' + page,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          console.log(response.data.hits.hits)
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            "id": element._source.id,
            "timestamp": timestamp,
            "agent_id": element._source.agent.id,
            "agent_name": element._source.agent.name,
            "agent_ip": element._source.agent.ip,
            "os_type": element._source.agent.labels.os_type,
            "ruleid": element._source.rule.id,
            "level": element._source.rule.level,
            "description": element._source.rule.description,
            "srcip": element._source.data.srcip,
            "country_name": element._source.GeoLocation.country_name,
            "region_name": element._source.GeoLocation.region_name,
            "full_log": element._source.full_log,
            // "rule_info" : element._source.rule.mitre?.technique
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const updateDate = () => {
    const newJson = {
      "size": 1000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {

        "bool": {
          "filter": [
            { "term": { "decoder.name": "ar_log_json" } },
            {
              "range": {
                "timestamp": {
                  "gte": startdate,
                  "lt": enddate
                }
              }
            }
          ]
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            // "id" : element._source.id,
            // "timestamp" : timestamp,
            // "agent_name":element._source.agent.name,
            // "description" : element._source.rule.description,
            // "level" : element._source.rule.level,
            // "ruleid" : element._source.rule.id,
            "id": element._source.id,
            "timestamp": timestamp,
            "agent_id": element._source.agent.id,
            "agent_name": element._source.agent.name,
            "agent_ip": element._source.agent.ip,
            "os_type": element._source.agent.labels.os_type,
            "ruleid": element._source.rule.id,
            "level": element._source.rule.level,
            "description": element._source.rule.description,
            "srcip": element._source.data.srcip,
            "country_name": element._source.GeoLocation.country_name,
            "region_name": element._source.GeoLocation.region_name,
            "full_log": element._source.full_log,
            // "rule_info" : element._source.rule.mitre?.technique
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFilterJson(newJson);
  }

  const resetDate = () => {
    const newJson = {
      "size": 1000,
      "sort": [
        { "timestamp": "desc" }
      ],
      "query": {

        "bool": {
          "filter": [
            { "term": { "decoder.name": "ar_log_json" } }
          ]
        }
      }
    };

    setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + '/connectors/workstation/alerts',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            // "id" : element._source.id,
            // "timestamp" : timestamp,
            // "agent_name":element._source.agent.name,
            // "description" : element._source.rule.description,
            // "level" : element._source.rule.level,
            // "ruleid" : element._source.rule.id,
            "id": element._source.id,
            "timestamp": timestamp,
            "agent_id": element._source.agent.id,
            "agent_name": element._source.agent.name,
            "agent_ip": element._source.agent.ip,
            "os_type": element._source.agent.labels.os_type,
            "ruleid": element._source.rule.id,
            "level": element._source.rule.level,
            "description": element._source.rule.description,
            "srcip": element._source.data.srcip,
            "country_name": element._source.GeoLocation.country_name,
            "region_name": element._source.GeoLocation.region_name,
            "full_log": element._source.full_log,
            // "rule_info" : element._source.rule.mitre?.technique
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFilterJson(newJson);
    setStartDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    setEndDate(new Date().toISOString());
  }

  return (
    <>
      <Toggle />
      <div className='ar-date-picker'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            size={'small'}
            renderInput={(props) => <TextField {...props} size={'small'} />}
            label="Start Date"
            value={startdate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} size={'small'} />}
            label="End Date"
            size={'small'}
            value={enddate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>
      </div>
      <div className='ar-apply-btn'>
        <Button variant="contained" onClick={updateDate} size={'small'}>
          Apply
        </Button>
      </div>
      <div className='ar-reset-btn'>
        <Button variant="contained" onClick={resetDate} size={'small'}>
          Reset
        </Button>
      </div>
      <br />
      <Card>

        <Box style={{ height: 410, width: '100%' }}>
          <style>
            {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
          </style>
          <DataGrid
            rows={data}
            rowCount={totalRows}
            loading={loading}
            rowsPerPageOptions={[10]}
            rowHeight={30}
            pagination
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
          />
        </Box>
      </Card>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6">Alert Details</Typography>
            <Button onClick={handleCloseModal} variant="outlined" color='error' size="small">
              Close
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              mt: 2,
              maxHeight: "60vh",
            }}
          >
            {selectedRecord && (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "55vh",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                }}
              >
                <Table
                  sx={{
                    display: "block",
                    overflowX: "auto",
                    "& .MuiTableCell-root": {
                      padding: "4px 8px",
                      fontSize: "12px",
                      border: "1px solid #ccc",
                    },
                    "& .MuiTableRow-root": {
                      height: "24px",
                    },
                  }}
                >
                  <TableBody>
                    {Object.entries(selectedRecord)
                      .filter(([key]) => key !== "id")
                      .map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {key.replace("_", " ").toUpperCase()}
                          </TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ActiveReponse