import React from 'react';
import { Card, Typography, Grid, Button, Box } from '@mui/material';
import { WhatsApp, Email, Call, Computer, Settings } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

const Notifications = () => {
  const data = {
    teams: {
      details: { webhook_url: { name: 'Webhook URL' } },
      config_json: {
        webhook_url: 'https://example.webhook.url',
      },
      is_active: true,
    },
    email: {
      details: { email_id: { name: 'Email ID' } },
      config_json: { email_id: ['amit_gadkari@khika.com'] },
      is_active: true,
    },
    voice_call: {
      details: { phone_number: { name: 'Phone Number' } },
    },
    whatsapp: {
      details: { phone_number: { name: 'Phone Number' } },
    },
    zoho_desk: {
      details: {
        code: { name: 'Code' },
        org_id: { name: 'Org ID' },
        client_id: { name: 'Client ID' },
      },
    },
    service_now: {
      details: {
        instance: { name: 'Instance' },
        username: { name: 'Username' },
      },
    },
  };

  const comingSoonKeys = ['voice_call', 'whatsapp', 'zoho_desk', 'service_now'];

  const logos = {
    whatsapp: <WhatsApp style={{ fontSize: 40, color: '#25D366' }} />,
    email: <Email style={{ fontSize: 40, color: '#D44638' }} />,
    voice_call: <Call style={{ fontSize: 40, color: '#1E88E5' }} />,
    zoho_desk: <Settings style={{ fontSize: 40, color: '#FF5722' }} />,
    service_now: <Computer style={{ fontSize: 40, color: '#4CAF50' }} />,
    teams: <Settings style={{ fontSize: 40, color: '#0078D7' }} />,
  };

  const mapping_data = {
    alerts_connectors_mapping: {
      wow_110: {
        title: 'Certain Nodes consuming continuously High CPU',
        connector_mapping: ['teams', 'email'],
      },
      wow_113: {
        title: 'Certain Nodes consuming continuously High RAM',
        connector_mapping: ['teams', 'email'],
      },
      wow_102: {
        title: 'Unusually High Error/Warning detected in system',
        connector_mapping: ['teams', 'email'],
      },
      wow_125: {
        title: 'Qlik service status check',
        connector_mapping: ['email'],
      },
    },
  };

  const rows = Object.entries(mapping_data.alerts_connectors_mapping).map(([id, alert]) => ({
    id,
    title: alert.title,
    connector_mapping: alert.connector_mapping || [],
  }));

  const connectors = ['teams', 'email', 'voice_call', 'whatsapp', 'zoho_desk', 'service_now'];

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      renderCell: (params) => (
        <Typography style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{params.value}</Typography>
      ),
    },
    {
      field: 'connector_mapping',
      headerName: 'Connectors',
      flex: 3,
      renderCell: (params) => (
        <Box display="flex" flexWrap="wrap" gap={1}>
          {params.value.map((connector) => (
            <Box key={connector} display="flex" alignItems="center">
              <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                {connector.replace('_', ' ')}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12} md={6}>
        <Box
          style={{
            border: '1px solid #ccc',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box style={{ height: '450px', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#f5f5f5',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                },
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          {Object.entries(data).map(([key, value]) => (
            <Grid item xs={12} sm={6} md={6} key={key}>
              <Card
                variant="outlined"
                style={{
                  borderRadius: '12px',
                  height: '140px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '16px',
                }}
              >
                <Box display="flex" alignItems="center" gap="16px">
                  {logos[key]}
                  <Typography variant="h6" component="div">
                    {key
                      .replace(/_/g, ' ')
                      .replace(/\b\w/, (char) => char.toUpperCase())}
                  </Typography>
                </Box>
                <Box>
                  {comingSoonKeys.includes(key) && (
                    <Button variant="contained" color="secondary" disabled>
                      Coming Soon...
                    </Button>
                  )}
                  {['teams', 'email'].includes(key) && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '10px' }}
                    >
                      {value.config_json
                        ? 'Manage Notification'
                        : 'Add Notification'}
                    </Button>
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notifications;