import React, { useState, useEffect } from 'react';
import {
  Card,
  Box,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Toggle from './Toggle';

const activeResponseData = {
  took: 164,
  timed_out: false,
  _shards: {
    total: 45,
    successful: 45,
    skipped: 43,
    failed: 0,
  },
  hits: {
    total: {
      value: 285,
      relation: 'eq',
    },
    max_score: null,
    hits: [
      {
        _index: 'khika2-z2ftzxjzltqy-alerts-2024.11.27',
        _id: '8PtxbJMBDkwrtxsJaSBg',
        _score: null,
        _source: {
          cluster: {
            node: 'khika-master-node',
            name: 'KHIKA',
          },
          agent: {
            ip: '10.128.0.4',
            name: 'wowizer-dev-2023',
            id: '065',
            labels: {
              os_type: 'Linux',
              connector_type: 'workstation',
              active_response: 'true',
              tenant: 'z2ftzxjzltqy',
            },
          },
          data: {
            srcip: '43.252.103.253',
            origin: {
              module: 'wazuh-execd',
              name: 'khika-master-node',
            },
            version: '1',
            parameters: {
              alert: {
                data: {
                  srcip: '43.252.103.253',
                  alert_uuid: '20ebae76-d520-49e1-ad10-b3d3c4ea091a',
                },
              },
              extra_args: [],
              program: 'active-response/bin/firewall-drop',
            },
            command: 'delete',
          },
          rule: {
            firedtimes: 2,
            mail: false,
            level: 3,
            pci_dss: ['11.4'],
            tsc: ['CC6.1', 'CC6.8', 'CC7.2', 'CC7.3', 'CC7.4'],
            description: 'Host Unblocked by firewall-drop Active Response',
            groups: ['ossec', 'active_response'],
            id: '652',
            nist_800_53: ['SI.4'],
            gpg13: ['4.13'],
            gdpr: ['IV_35.7.d'],
          },
          full_log:
            '2024/11/27 12:36:28 active-response/bin/firewall-drop: {"version":1,"origin":{"name":"khika-master-node","module":"wazuh-execd"},"command":"delete","parameters":{"extra_args":[],"alert":{"data":{"srcip":"43.252.103.253","alert_uuid":"20ebae76-d520-49e1-ad10-b3d3c4ea091a"}},"program":"active-response/bin/firewall-drop"}}',
          id: '1732691190.416028263',
          timestamp: '2024-11-27T07:06:30.511+0000',
          manager: {
            name: 'khika2-saas',
          },
          decoder: {
            parent: 'ar_log_json',
            name: 'ar_log_json',
          },
          input: {
            type: 'log',
          },
          '@timestamp': '2024-11-27T07:06:30.511Z',
          location: '/var/ossec/logs/active-responses.log',
          GeoLocation: {
            city_name: 'Mumbai',
            country_name: 'India',
            region_name: 'Maharashtra',
            location: {
              lon: 72.8826,
              lat: 19.0728,
            },
          },
        },
        sort: [1732691190511],
      },
    ],
  },
};

const ActiveResponse = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [startdate, setStartDate] = useState(dayjs().startOf('day').subtract(1, 'day'));
  const [enddate, setEndDate] = useState(dayjs().endOf('day'));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);

  const columns = [
    { field: 'timestamp', headerName: 'Date Time', flex: 1 },
    { field: 'agent_name', headerName: 'Agent Name', flex: 1 },
    { field: 'ruleid', headerName: 'Rule Id' },
    { field: 'level', headerName: 'Level' },
    { field: 'description', headerName: 'Description', flex: 1.5 },
    {
      field: 'viewDetails',
      headerName: 'View Details',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" size="small" onClick={() => handleViewDetails(params.row)}>
          View Details
        </Button>
      ),
    },
  ];

  const loadData = () => {
    setLoading(true);
    const hits = activeResponseData.hits.hits;
    const formattedData = hits.map((element) => {
      const source = element._source;
      return {
        id: element._id,
        timestamp: new Date(source['@timestamp']).toLocaleString(),
        agent_name: source.agent.name,
        description: source.rule.description,
        level: source.rule.level,
        ruleid: source.rule.id,
        geolocation: source.GeoLocation || {},
        agent: source.agent,
        data: source.data,
        full_log: source.full_log,
      };
    });
    setData(formattedData);
    setTotalRows(hits.length);
    setLoading(false);
  };

  const updateDate = () => {
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.timestamp);
      return itemDate >= startdate.toDate() && itemDate <= enddate.toDate();
    });
    setData(filteredData);
  };

  const resetDate = () => {
    setStartDate(dayjs().startOf('day').subtract(1, 'day'));
    setEndDate(dayjs().endOf('day'));
    loadData();
  };

  const handleViewDetails = (row) => {
    setSelectedDetails(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDetails(null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Toggle />
      <div className="ar-date-picker" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="Start Date"
              value={startdate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="End Date"
              value={enddate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </div>
        <Box display="flex" gap={1}>
          <Button variant="contained" size="small" onClick={updateDate}>
            Apply
          </Button>
          <Button variant="outlined" size="small" onClick={resetDate}>
            Reset
          </Button>
        </Box>
      </div>
      <Box sx={{ height: 500, width: '100%', mt: 2 }}>
      <style>
          {`
            .MuiDataGrid-columnHeader {
              background-color: green;
              color: white;
            }
            .MuiDataGrid-cell {
              color: black;
            }
            .MuiDataGrid-footerContainer {
              background-color: lightgray;
            }
            .MuiDataGrid-row:nth-of-type(odd) {
              background-color: lightyellow;
            }
            .MuiDataGrid-row:nth-of-type(even) {
              background-color: white;
            }
          `}
        </style>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={loading}
          rowCount={totalRows}
          pagination
        />
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Active Response Details</DialogTitle>
  <DialogContent>
    <Table 
    sx={{
          '& .MuiTableCell-root': {
            padding: '4px 8px', // Compact padding
            fontSize: '12px',  // Smaller font size
            border: '1px solid #ccc', // Add border to each cell
          },
          '& .MuiTableRow-root': {
            height: '24px', // Reduce row height
          },
          borderCollapse: 'collapse', // Ensure borders don't double up
        }}>
      <TableBody>
        <TableRow>
          <TableCell><strong>Country</strong></TableCell>
          <TableCell>{selectedDetails?.geolocation?.country_name || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Latitude</strong></TableCell>
          <TableCell>{selectedDetails?.geolocation?.location?.lat || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Longitude</strong></TableCell>
          <TableCell>{selectedDetails?.geolocation?.location?.lon || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Region</strong></TableCell>
          <TableCell>{selectedDetails?.geolocation?.region_name || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Agent Name</strong></TableCell>
          <TableCell>{selectedDetails?.agent?.name || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Agent ID</strong></TableCell>
          <TableCell>{selectedDetails?.agent?.id || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Agent IP</strong></TableCell>
          <TableCell>{selectedDetails?.agent?.ip || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>OS Type</strong></TableCell>
          <TableCell>{selectedDetails?.agent?.labels?.os_type || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Data.srcip</strong></TableCell>
          <TableCell>{selectedDetails?.data?.srcip || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Full Log</strong></TableCell>
          <TableCell>{selectedDetails?.full_log || 'N/A'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>Rule Description</strong></TableCell>
          <TableCell>{selectedDetails?.description || 'N/A'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary" variant="contained">
      Close
    </Button>
  </DialogActions>
</Dialog>
    </>
  );
};

export default ActiveResponse;
