import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Box, Typography } from "@mui/material";
import { BaseUrl } from "../../constants/constants";

const Landing = () => {
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details?.data?.accessToken;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          BaseUrl + "/user/soc_dash_data",
          {
            startTime: "2025-01-17T18:30:00.000Z",
            endTime: "2025-01-27T18:29:59.999Z",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Typography>Loading...</Typography>;
  if (!data) return <Typography>No data available</Typography>;

  const alertsData = Object.entries(data.alertsCountData).map(([key, value]) => ({
    name: key,
    ...value,
  }));

  const vulnerabilityData = Object.entries(data.vulnerabilityCountData).map(
    ([key, value]) => ({ name: key, value })
  );

  const cisData = Object.entries(data.cisCountData).map(([key, value]) => ({
    name: key,
    value,
  }));

  const bruteforceData = Object.entries(data.bruteforceCountData).map(
    ([key, value]) => ({ name: key, count: Object.values(value)[0] })
  );

  const threatIntelCharts = [
    {
      title: "Malicious IP Count",
      data: Object.entries(data.threatIntelData.maliciousipCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
    {
      title: "Malicious File Count",
      data: Object.entries(data.threatIntelData.maliciousFileCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
    {
      title: "Malicious URL Count",
      data: Object.entries(data.threatIntelData.maliciousURLCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
    {
      title: "Malicious Link Count",
      data: Object.entries(data.threatIntelData.maliciousLINKCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
    {
      title: "Malicious Domain Count",
      data: Object.entries(data.threatIntelData.maliciousDomainCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
    {
      title: "Total Threat Count",
      data: Object.entries(data.threatIntelData.totalThreatCountData).map(
        ([key, value]) => ({ name: key, value })
      ),
    },
  ];

  const threatSeverityData = Object.entries(
    data.threatIntelData.threatSeverityCountData
  ).map(([key, value]) => ({
    name: key,
    ...value,
  }));

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Adjust the number of columns dynamically
        gap: 2,
        padding: 2,
      }}
    >
      {/* Alerts Bar Chart */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Alerts Count
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={alertsData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="critical" fill="#f44336" name="Critical" />
            <Bar dataKey="high" fill="#ff9800" name="High" />
            <Bar dataKey="medium" fill="#ffc107" name="Medium" />
            <Bar dataKey="low" fill="#4caf50" name="Low" />
            <Bar dataKey="info" fill="#2196f3" name="Info" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Vulnerability Pie Chart */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Vulnerabilities
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={vulnerabilityData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
            >
              {vulnerabilityData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>

      {/* CIS Pie Chart */}
      <Box>
        <Typography variant="h6" gutterBottom>
          CIS Count
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={cisData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
            >
              {cisData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>

      {/* Bruteforce Count Bar Chart */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Bruteforce Count
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={bruteforceData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Threat Intel Charts */}
      {threatIntelCharts.map((chart, index) => (
        <Box key={index}>
          <Typography variant="h6" gutterBottom>
            {chart.title}
          </Typography>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={chart.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="value"
                fill={COLORS[index % COLORS.length]}
                name={chart.title}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      ))}

      {/* Threat Severity Chart */}
      <Box>
        <Typography variant="h6" gutterBottom>
          Threat Severity Count
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={threatSeverityData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="undefined" fill="#8884d8" name="Undefined" />
            <Bar dataKey="medium" fill="#ffc107" name="Medium" />
            <Bar dataKey="high" fill="#ff9800" name="High" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default Landing;