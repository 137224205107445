import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const ActiveResponse = () => {
  const [open, setOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);

  // Function to open the dialog with the selected active response's details
  const handleOpen = (response) => {
    setSelectedResponse(response);
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedResponse(null);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'timestamp', headerName: 'Date Time', flex: 1 },
    { field: 'response_name', headerName: 'Response Name', flex: 1 },
    { field: 'action_id', headerName: 'Action ID', flex: 1 },
    { field: 'action_info', headerName: 'Action Info', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },

    // Add the "View Details" column after the description column
    {
      field: 'viewDetails',
      headerName: 'View Details',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" size="small" onClick={() => handleOpen(params.row)}>
          View Details
        </Button>
      ),
    },
  ];

  const rows = [
    { 
      id: 1, 
      timestamp: '2024-11-15 10:00:00', 
      response_name: 'Response1', 
      action_id: 'A1', 
      action_info: 'Action1 Info', 
      status: 'Success', 
      description: 'Sample description for active response 1' 
    },
    { 
      id: 2, 
      timestamp: '2024-11-15 11:00:00', 
      response_name: 'Response2', 
      action_id: 'A2', 
      action_info: 'Action2 Info', 
      status: 'Pending', 
      description: 'Sample description for active response 2' 
    },
    { 
      id: 3, 
      timestamp: '2024-11-15 12:00:00', 
      response_name: 'Response3', 
      action_id: 'A3', 
      action_info: 'Action3 Info', 
      status: 'Failed', 
      description: 'Sample description for active response 3' 
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      {/* DataGrid displaying active response rows */}
      <DataGrid rows={rows} columns={columns} pageSize={5} />

      {/* Dialog to show active response details when the "View Details" button is clicked */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Active Response Details</DialogTitle>
        <DialogContent>
          {selectedResponse && (
            <>
              <Typography><strong>Date Time:</strong> {selectedResponse.timestamp}</Typography>
              <Typography><strong>Response Name:</strong> {selectedResponse.response_name}</Typography>
              <Typography><strong>Action ID:</strong> {selectedResponse.action_id}</Typography>
              <Typography><strong>Action Info:</strong> {selectedResponse.action_info}</Typography>
              <Typography><strong>Status:</strong> {selectedResponse.status}</Typography>
              <Typography><strong>Description:</strong> {selectedResponse.description}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActiveResponse;
