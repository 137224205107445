import React, { useEffect, useState } from "react";
import { MenuItem, Select, CircularProgress, Typography, FormControl, Checkbox, ListItemText, InputLabel, OutlinedInput, Grid } from "@mui/material";
import axios from "axios";
import { BaseUrl } from "../../constants/constants";

const TenantSelector = ({ onTenantChange }) => {
    const [tenantOptions, setTenantOptions] = useState([]);
    const [selectedTenants, setSelectedTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user_details = JSON.parse(window.localStorage.getItem("user_details") || "{}");
    const token = user_details?.data?.accessToken;

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await axios.get(BaseUrl + "/user/list_soc_mapped_users", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const tenantNames = response.data.map((user) => user.tenantName);
                const uniqueTenants = [...new Set(tenantNames)];
                setTenantOptions(uniqueTenants);
                setSelectedTenants(uniqueTenants);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch tenants.");
                setLoading(false);
            }
        };

        fetchTenants();
    }, [token]);

    useEffect(() => {
        onTenantChange(selectedTenants);
    }, [selectedTenants, onTenantChange]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    const handleTenantSelection = (tenant) => {
        let data = JSON.stringify({
        "tenant": tenant
        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseUrl + '/auth/iKzmfludhj',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
            localStorage.setItem("tenant_token", response.data.user_ktoken);
        })
        .catch((error) => {
        console.log(error);
        });
        localStorage.setItem("selectedtenant", tenant);
        setSelectedTenant(tenant);
        window.open("/dashboard/viewtenant", "_blank");
    };

    return (
        <>
            <br />
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel>Tenants</InputLabel>
                        <Select
                            multiple
                            value={selectedTenants}
                            onChange={(e) => setSelectedTenants(e.target.value)}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        width: 'auto',
                                    },
                                },
                            }}
                            sx={{
                                width: "100%",
                            }}
                            input={<OutlinedInput label="Tenants" />}
                        >
                            {tenantOptions.map((tenant) => (
                                <MenuItem key={tenant} value={tenant}>
                                    <Checkbox checked={selectedTenants.includes(tenant)} />
                                    <ListItemText primary={tenant} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl sx={{ width: 200 }}>
                        <InputLabel>View Tenant</InputLabel>
                        <Select
                            value={selectedTenant}
                            onChange={(e) => handleTenantSelection(e.target.value)}
                            renderValue={(selected) => selected || "Select a tenant"}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        width: 'auto',
                                    },
                                },
                            }}
                            sx={{
                                width: "100%",
                            }}
                            input={<OutlinedInput label="View Tenant" />}
                        >
                            {tenantOptions.map((tenant) => (
                                <MenuItem key={tenant} value={tenant}>
                                    <ListItemText primary={tenant} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default TenantSelector;