import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import EmailActivation from './components/EmailActivation';
import Logout from './components/Logout';
import Home from './components/Home';
import CIS_Module from './components/CIS_Module';
import ConnectDevices from './Device_CMD';
import DashboardLayout from './components/Dashboard_layout';
import Profile from './components/Profile';
import ThemeProvider from './theme';
import AlertsTab from './components/AlertsTab';
import Connecters from './components/Connecters';
import Syslog from './components/Syslog';
import Cloud from './components/Cloud';
import CloudAccount from './components/CloudAccount';
import UpdateCloud from './components/CloudForms/UpdateCloud';
import Reporting from './components/Reporting';
import ResetConfirmPassword from './components/ResetConfirmPassword';
import Modules from './components/Modules';
import Reports_Dashboard from './components/Reports_Dashboard';
import ManageUsers from './components/ManageUsers';
import Newdashboard from './components/NewDashboard';
import ChatBot from './components/ChatBot';
import DataSourceBarGraph from './components/GodigitDashboards/DataSourceBarGraph';
import Discover from './components/Discover';
import NewLogin from './components/NewLogin';
import NewRegister from './components/NewRegister';
import Troubleshoot from './components/Troubleshoot';
import Generate_Report from './components/Generate_Report';
import Custom_Reports from './components/Custom_Reports';
import AlertDiscover from './components/AlertDiscover';
import DataManagement from './components/DataManagement';
import AlertConfiguration from './components/AlertConfiguration';
import Register1 from './components/Register1';
import CustomReports1 from './components/CustomReports1';
import AlertsTab1 from './components/AlertTab1';
import Notification_Channel from './components/Notification_Channel';
import Navbar1 from './components/Navbar1';
import Playbook from './Playbook';
import Playbook1 from './components/Playbook1';
import DataStatus from './components/DataStatus';
import Navbar2 from './components/Navbar2';
import Misp from './components/Misp';
import Alerts2 from './components/Alerts2';
import SocHome from './components/SOC/SocHome';
import Dashboards_Landing from './components/SOC_Dashboards/Dashboards_Landing';

function App() {
  return (
<ThemeProvider>
    <Router>
      <Routes>
        <Route path="/" element={<NewLogin />} />
        <Route path="/newlogin" element={<NewRegister />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/register" element={<Register1 />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/auth/reset-pwd/confirm/" element={<ResetConfirmPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/email-confirmation/:token" element={<EmailActivation />} />
        <Route path="/troubleshoot" element={<Troubleshoot />} />
        <Route path="/navbar1" element={<Navbar1 />} />
        <Route path="/navbar2" element={<Navbar2 />} />
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="soc" element={<Dashboards_Landing />} />
          {/* <Route path="soc2" element={<Dashboards_Landing />} />
          <Route path="soc3" element={<Charts />} /> */}
          <Route path="viewtenant" element={<SocHome />} />
          <Route path="connectors" element={<Connecters />} />
          <Route path="connect_devices" element={<ConnectDevices />} />
          <Route path="cis" element={<CIS_Module />} />
          <Route path="reporting" element={<Reporting />} />
          {/* <Route path="customreports" element={<CustomReports />} /> */}
          <Route path="customreports1" element={<CustomReports1 />} />
          <Route path="configure_reports" element={<Custom_Reports />} />
          <Route path="generate_reports" element={<Generate_Report />} />
          <Route path="data_management" element={<DataManagement />} />
          <Route path="alert_config" element={<AlertConfiguration />} />
          <Route path="reports_dashboard" element={<Reports_Dashboard />} />
          <Route path="alerts" element={<AlertsTab />} />
          <Route path="alerts2" element={<Alerts2 />} />
          <Route path="misp" element={<Misp />} />
          <Route path="alerts1" element={<AlertsTab1 />} />
          <Route path="manageusers" element={<ManageUsers />} />
          <Route path="datainsights" element={<Newdashboard />} />
          <Route path="archivediscover" element={<Discover />} />
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="godashboards" element={<DataSourceBarGraph />} />
          <Route path="alertdiscover" element={<AlertDiscover />} />
          <Route path="notifications" element={<Notification_Channel />} />
          <Route path="playbook" element={<Playbook />} />
          <Route path="playbook1" element={<Playbook1 />} />
          <Route path="datastatus" element={<DataStatus />} />
          {/* <Route path="notifications1" element={<Notification_Channel />} /> */}
          <Route path="alerts" element={<AlertsTab />} />
          <Route path="profile" element={<Profile />} />
          <Route path="syslogdevices" element={<Syslog />} />
          <Route path="cloud" element={<Cloud />} />
          <Route path="add-cloud-account" element={<CloudAccount />} />
          <Route path="/dashboard/update-cloud-account/" element={<UpdateCloud />} />
          <Route path="/dashboard/modules/security-management/security-event" element={<Modules />} />
          <Route path="/dashboard/modules/security-management/integrity-monitoring" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/policy-monitoring" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/system-auditing" element={<Modules />} />
          <Route path="/dashboard/modules/auditing-policy-monitoring/security-configuration" element={<Modules />} />
          <Route path="/dashboard/modules/threat-detection/vulnerabilities" element={<Modules />} />
          <Route path="/dashboard/modules/threat-detection/mitre-attack" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/pci-dss" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/nist-800-53" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/tsc" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/gdpr" element={<Modules />} />
          <Route path="/dashboard/modules/regulatory-compliance/hipaa" element={<Modules />} /> */
        </Route>
      </Routes>
    </Router>
  </ThemeProvider>
  );
}

export default App;
