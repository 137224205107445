import React, { useEffect, useState } from "react";
import { MenuItem, Select, Checkbox, ListItemText, FormControl, InputLabel, OutlinedInput, RadioGroup, FormControlLabel, Radio, Box, Typography, CircularProgress, Button } from "@mui/material";
import { fetchDataUsage, fetchDevicesData, fetchUsers } from "../../api/api";
import Charts from './Charts';
import { BaseUrl } from "../../constants/constants";
import axios from "axios";

const Dashboards_Landing = () => {
    const [userList, setUserList] = useState([]);
    const [selectedTenants, setSelectedTenants] = useState([]);
    const [timeRange, setTimeRange] = useState("24h");
    const [devicesData, setDevicesData] = useState(null);
    const [dataUsage, setDataUsage] = useState(null);
    const [loading, setLoading] = useState(true);
    const user_details = JSON.parse(window.localStorage.getItem("user_details"));
    const token = user_details?.data?.accessToken;

    // useEffect(() => {
    //     localStorage.removeItem("selectedtenant", null);
    // }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const users = await fetchUsers();
                setUserList(users);
                setSelectedTenants(users.map(user => user.tenantName));
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchData();
    }, []);

    const handleTenantChange = (event) => {
        const { value } = event.target;
        setSelectedTenants(value);
    };

    const handleTimeRangeChange = (event) => {
        const { value } = event.target;
        setTimeRange(value);
    };

    useEffect(() => {
        if (selectedTenants.length > 0) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const devices = await fetchDevicesData(selectedTenants);
                    setDevicesData(devices);

                    const usage = await fetchDataUsage(selectedTenants, timeRange);
                    setDataUsage(usage);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedTenants, timeRange]);

    const handleSelectAll = () => {
        const allTenantNames = userList.map(user => user.tenantName);
        setSelectedTenants(allTenantNames);
    };

    const handleDeselectAll = () => {
        setSelectedTenants([]);
    };

    const calculateTotalCounts = () => {
        let totalDevices = 0;
        let totalActive = 0;
        let totalInactive = 0;

        selectedTenants.forEach(tenant => {
            const tenantData = devicesData[tenant];
            if (tenantData) {
                totalDevices += tenantData.agents.total + tenantData.syslog_devices.total;
                totalActive += tenantData.agents.active + tenantData.syslog_devices.active;
                totalInactive += tenantData.agents.inactive + tenantData.syslog_devices.inactive;
            }
        });

        return { totalDevices, totalActive, totalInactive };
    };

    const calculateTotalDataUsage = () => {
        let totalData = 0;
        selectedTenants.forEach(tenant => {
            const tenantData = dataUsage[tenant];
            if (tenantData) {
                totalData += tenantData.gigabytes;
            }
        });

        return totalData;
    };

    const { totalDevices, totalActive, totalInactive } = devicesData ? calculateTotalCounts() : { totalDevices: 0, totalActive: 0, totalInactive: 0 };
    const totalData = dataUsage ? calculateTotalDataUsage() : 0;

    const handleViewTenant = (tenantName, event) => {
        event.stopPropagation();

        let data = JSON.stringify({
            "tenant": tenantName
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseUrl + '/auth/iKzmfludhj',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response);
                localStorage.setItem("tenant_token", response.data.user_ktoken);
                localStorage.setItem("tenant_access_switched", response.data.user_atoken.accessToken);
            })
            .catch((error) => {
                console.log(error);
            });
        localStorage.setItem("selectedtenant", tenantName);
        window.open("/dashboard/viewtenant", "_blank");
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%', marginLeft: '0.5%' }}>
                <FormControl sx={{ width: 300 }} variant="outlined">
                    <InputLabel shrink>User</InputLabel>
                    <Select
                        multiple
                        value={selectedTenants}
                        onChange={handleTenantChange}
                        renderValue={(selected) => selected.join(", ")}
                        input={<OutlinedInput label="User" />}
                        sx={{
                            width: '100%',
                            paddingTop: '0.5px',
                            paddingBottom: '0.5px',
                        }}
                    >
                        <MenuItem onClick={handleSelectAll}>
                            <Checkbox checked={selectedTenants.length === userList.length} />
                            <ListItemText primary="Select All" />
                        </MenuItem>
                        <MenuItem onClick={handleDeselectAll}>
                            <Checkbox checked={selectedTenants.length === 0} />
                            <ListItemText primary="Deselect All" />
                        </MenuItem>

                        {userList.map((user) => (
                            <MenuItem key={user.tenantName} value={user.tenantName}>
                                <Checkbox checked={selectedTenants.indexOf(user.tenantName) > -1} />
                                <ListItemText primary={user.company_name} />
                                <Button
                                    variant="contained"
                                    onClick={(event) => handleViewTenant(user.tenantName, event)}
                                    sx={{ marginLeft: '8px', fontSize: '0.75rem' }}
                                >
                                    View Tenant
                                </Button>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '70px', width: '40%', marginTop: '0%' }}>
                    <Box sx={{ textAlign: 'center', backgroundColor: '#08aad2', padding: 1, width: '33%', borderRight: '1px solid #ddd', height: '100%' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>Total</Typography>
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>{totalDevices}</Typography>}
                    </Box>
                    <Box sx={{ textAlign: 'center', backgroundColor: '#69ae34', padding: 1, width: '33%', borderRight: '1px solid #ddd', height: '100%' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>Active</Typography>
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>{totalActive}</Typography>}
                    </Box>
                    <Box sx={{ textAlign: 'center', backgroundColor: '#dfb52c', padding: 1, width: '33%', height: '100%' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>Disconnected</Typography>
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>{totalInactive}</Typography>}
                    </Box>
                    <Box sx={{ textAlign: 'center', backgroundColor: '#08aad2', padding: 1, width: '33%', height: '100%' }}>
                        <Typography variant="h6" sx={{ color: 'white' }}>Data (GB)</Typography>
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>{totalData.toFixed(2)}</Typography>}
                    </Box>
                </Box>

                <RadioGroup
                    row
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                >
                    <FormControlLabel value="24h" control={<Radio />} label="Last 24 Hours" />
                    <FormControlLabel value="48h" control={<Radio />} label="Last 48 Hours" />
                    <FormControlLabel value="7d" control={<Radio />} label="Last 7 Days" />
                </RadioGroup>
            </Box>
            <br /><br />
            <Charts selectedTenants={selectedTenants} timeRange={timeRange} />
        </div>
    );
};

export default Dashboards_Landing;