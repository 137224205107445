import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const DataManagement = () => {
  const [filterStatus, setFilterStatus] = useState('');

  const handleRestore = (date) => {
    console.log(`Restoring index for date: ${date}`);
  };

  const handleDelete = (date) => {
    console.log(`Deleting restored data for date: ${date}`);
  };

  const rows = [
    { id: 1, date: '2023-09-21', status: 'snapshot', restored_at: '-' },
    { id: 2, date: '2023-09-18', status: 'completed', restored_at: '2023-09-19' },
    { id: 3, date: '2023-09-15', status: 'in_progress', restored_at: '-' },
    { id: 4, date: '2023-09-10', status: 'delete_in_progress', restored_at: '2023-09-12' },
  ];

  const filteredRows = filterStatus
    ? rows.filter((row) => row.status === filterStatus)
    : rows;

  const columns = [
    { field: 'date', headerClassName: 'super-app-theme--header', headerName: 'Index Date', flex: 0.3, cellClassName: () => 'word-wrap' },
    { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Restore Status', flex: 0.3, cellClassName: () => 'word-wrap',
      renderCell: (params) => (
        <div>
          {params.value === 'snapshot' ? 'Available to restore' : params.value === 'delete_in_progress' ? 'Deletion in Progress' : params.value === 'completed' ? 'Restored' : 'Restore in Progress'}
        </div>
      )
    },
    { field: 'restored_at', headerClassName: 'super-app-theme--header', headerName: 'Restored At', flex: 0.3, cellClassName: () => 'word-wrap' },
    {
      field: 'action',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      flex: 0.2,
      renderCell: (params) => (
        <div>
          {params.row.status === 'snapshot' ? (
            <Button size="small" variant="outlined" color="primary" onClick={() => handleRestore(params.row.date)}>
              Restore
            </Button>
          ) : params.row.status === 'completed' ? (
            <Button size="small" variant="outlined" color="error" onClick={() => handleDelete(params.row.date)}>
              &nbsp;&nbsp;Delete&nbsp;&nbsp;
            </Button>
          ) : params.row.status === 'delete_in_progress' ? (
            'Deletion in Progress'
          ) : (
            'In Progress'
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: '70vh', width: '100%', marginTop: '2%' }}>
        <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <FormControl style={{ marginBottom: 20, width: '200px', marginLeft: '1%' }}>
        <InputLabel id="status-filter-label">Restore Status</InputLabel>
        <Select
          labelId="status-filter-label"
          id="status-filter"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          label="Restore Status"
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="completed">Restored</MenuItem>
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="snapshot">Available to Restore</MenuItem>
        </Select>
      </FormControl>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
};

export default DataManagement;