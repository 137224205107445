import React, { useEffect, useState } from "react";
import EventSeverityCount from "./EventSeverityCount";
import TenantSelector from "./TenantSelector";
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, CircularProgress, Typography } from "@mui/material";
import { fetchSocDashLogData, fetchSocDashWData } from "../../api/api";
import AgentsBarChart from "./AgentsBarChart";
import SyslogDevicesBarChart from "./SyslogDevicesBarChart";
import DevicesBarChart from "./DevicesBarChart";

const SocLanding = () => {
    const [selectedTenants, setSelectedTenants] = useState([]);
    const [timeRange, setTimeRange] = useState("24h");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleTenantChange = (tenants) => {
        setSelectedTenants(tenants);
    };

    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };

    const getDayRange = (timeRange) => {
        switch (timeRange) {
            case "24h":
                return 1;
            case "48h":
                return 2;
            case "7d":
                return 7;
            default:
                return 1;
        }
    };

    useEffect(() => {
        if (selectedTenants.length === 0 || data) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchSocDashWData(selectedTenants);
                setData(response.devices_data);
            } catch (err) {
                setError("Failed to fetch dashboard data.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedTenants, data]);

    useEffect(() => {
        if (selectedTenants.length === 0 || data) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const dayRange = getDayRange(timeRange);
                const response = await fetchSocDashLogData(selectedTenants, dayRange);
                setData(response.devices_data);
            } catch (err) {
                setError("Failed to fetch dashboard data.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedTenants, timeRange, data]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }    

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    const severityColors = { critical: "#f44336", high: "#ff9800", medium: "#ffc107", low: "#4caf50", info: "#2196f3" };

    return (
        <Box sx={{ flexGrow: 1, p: 1, backgroundColor: "#f9f9f9" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
                <Box>
                    <TenantSelector onTenantChange={handleTenantChange} />
                </Box>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                        aria-label="Time Range"
                    >
                        <FormControlLabel value="24h" control={<Radio />} label="Last 24 Hours" />
                        <FormControlLabel value="48h" control={<Radio />} label="Last 48 Hours" />
                        <FormControlLabel value="7d" control={<Radio />} label="Last 7 Days" />
                    </RadioGroup>
                </FormControl>
            </Box>

            <EventSeverityCount selectedTenants={selectedTenants} timeRange={timeRange} severityColors={severityColors} />

            <Grid container spacing={2} sx={{ marginTop: 50 }}>
                <Grid item xs={12} md={6}>
                    <AgentsBarChart tenantNames={selectedTenants} data={data} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SyslogDevicesBarChart tenantNames={selectedTenants} data={data} />
                </Grid>
            </Grid>

            {/* <DevicesBarChart data={data} /> */}
        </Box>
    );
};

export default SocLanding;