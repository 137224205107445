import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Tooltip, IconButton, Button, Stack, Modal, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import swal from 'sweetalert';
import alertData from './alertData.json';

const Custom_Reports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [openConfigModal, setOpenConfigModal] = useState(false); 
  const [emailList, setEmailList] = useState(''); 

  useEffect(() => {
    const formattedData = alertData.alert_id.map((item, index) => ({
      id: index + 1,
      khika_report_id: item.ids,
      title: item.description,
      status: false,
      desc: item.description,
      mail_list: ''
    }));
    setData(formattedData);
    setLoading(false);
  }, []);

  const handleToggleStatus = (reportId) => {
    setData((prevData) =>
      prevData.map((report) =>
        report.khika_report_id === reportId
          ? { ...report, status: !report.status }
          : report
      )
    );
  };

  const handleEnableAll = () => {
    setData((prevData) =>
      prevData.map((report) => ({ ...report, status: true }))
    );
  };

  const handleDisableAll = () => {
    setData((prevData) =>
      prevData.map((report) => ({ ...report, status: false }))
    );
  };

  const handleConfigEmail = () => {
    setOpenConfigModal(true);
  };

  const handleCloseModal = () => {
    setOpenConfigModal(false); 
    setEmailList(''); 
  };

  const handleSaveEmailList = () => {
    swal('Email List Saved', `Email list: ${emailList}`, 'success'); 
    setOpenConfigModal(false); 
  };

  const columns = [
    { field: 'title', headerName: 'Title', flex: 2, cellClassName: 'word-wrap' },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (params) => (params.value ? 'Enabled' : 'Disabled'),
    },
    {
      field: 'info',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.desc} placement="top">
          <IconButton>
            <InfoIcon
              sx={{
                color: 'info.main',
                fontSize: 30
              }}
            />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      renderCell: (params) => {
        const { status, khika_report_id } = params.row;
        return (
          <Stack direction="row" spacing={1}>
            <Button
              variant={status ? "outlined" : "contained"}
              color={status ? "error" : "primary"}
              sx={{ width: '120px' }}
              onClick={() => handleToggleStatus(khika_report_id)}
            >
              {status ? "Deactivate" : "Activate"}
            </Button>
          </Stack>
        );
      },
    }
  ];

  const filteredData = data.filter((row) =>
    row.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ marginTop: '1%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
        <Typography variant="h5" component="h1">
          Alert Configurations
        </Typography>

        <Stack direction="row" spacing={2} sx={{ marginRight: '20px' }}>
          <Button variant="contained" color="success" onClick={handleEnableAll}>
            Enable All
          </Button>
          <Button variant="contained" color="error" onClick={handleDisableAll}>
            Disable All
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfigEmail}>
            Config Email
          </Button>
        </Stack>
      </Stack>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ height: '85vh', width: '100%' }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.khika_report_id}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#008000',
                color: 'white', 
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', 
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: 'lightyellow', 
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: 'white', 
              }
            }}
          />
        </Box>
      )}

      <Modal open={openConfigModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Configure Email List
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Enter email addresses separated by commas"
            value={emailList}
            onChange={(e) => setEmailList(e.target.value)}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ marginTop: 2 }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleSaveEmailList}>
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default Custom_Reports;
