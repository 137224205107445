import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Button, Switch, Box, Modal, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { BaseUrl } from '../constants/constants';
import swal from 'sweetalert';

const Notifications1 = () => {
  const [data, setData] = useState(null);
  const [masterConnectors, setMasterConnectors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isManageModalOpen, setManageModalOpen] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [selectedConnector, setSelectedConnector] = useState('');
  const [formData, setFormData] = useState({});
  const [manageFormData, setManageFormData] = useState({});
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;
  const [emailId, setEmailId] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: BaseUrl + '/notification-channel/',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.request(config);
      setData(response.data);
      setMasterConnectors(response.data.master_connectors);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedConnector('');
    setFormData({});
  };

  const handleOpenManageModal = (channel_id) => {
    let data = JSON.stringify({
      channel_id: channel_id,
    });
  
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/notification-channel/details',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        const responseData = response.data;
  
        const userConnectors = responseData.user_connectors;
        const channelKey = Object.keys(userConnectors)[0];
        const { config_json, channel_id, connector_name } = userConnectors[channelKey];
  
        const extractedData = {
          channel: channelKey,
          config_json,
          channel_id,
          connector_name,
        };
        console.log(extractedData);
        if(extractedData.connector_name === "email"){
          setEmailId(extractedData.config_json?.email_id || '')
        }else if(extractedData.connector_name === "teams"){
          setWebhookUrl(extractedData.config_json?.webhook_url || '')
        }else if(extractedData.connector_name === "voice_call"){
          setPhoneNumber(extractedData.config_json?.phone_number || '')
        }else{

        }
        setManageFormData(extractedData);
        setManageModalOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching channel details:', error);
      });
  };   

  const handleCloseManageModal = () => {
    setManageModalOpen(false);
  };

  const handleConnectorChange = (event) => {
    const connector = event.target.value;
    setSelectedConnector(connector);
    const fields = masterConnectors[connector]?.required || [];
    const initialFormData = fields.reduce((acc, field) => ({ ...acc, [field]: '' }), {});
    setFormData(initialFormData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    let configjson;

    if(selectedConnector === "email"){
      configjson = Object.keys(formData).reduce((acc, key) => {
        acc[key] = Array.isArray(formData[key]) ? formData[key] : [formData[key]];
        return acc;
      }, {});
    }else if(selectedConnector === "teams"){
      configjson = formData
    }else if(selectedConnector === "voice_call"){
      configjson = formData
    }else{
      configjson = {}
    }

    let data = JSON.stringify({
      "channel_name": channelName,
      "connector_name": selectedConnector,
      "config_json": configjson,
    });

    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: BaseUrl + '/notification-channel/',
    headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
    },
    data : data
    };
    
    axios.request(config)
    .then((response) => {
      swal({
        title: "Channel Added Successfully",
        text: "",
        icon: "success",
      }).then(function() {
        fetchData();
        handleCloseModal();
      });
    })
    .catch((error) => {
      console.log(error);
      handleCloseModal();
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const { user_connectors } = data;
  
  const handleChange = async (channelName, connectorName, channelId, status) => {
    let data = JSON.stringify({
      "channel_name": channelName,
      "connector_name": connectorName,
      "channel_id": channelId,
      "is_active": !status
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/notification-channel/status',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      fetchData();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const handleUpdateSubmit = () => {
    let data = JSON.stringify({
      channel_id: manageFormData.channel_id,
      channel_name: manageFormData.channel,
      connector_name: manageFormData.connector_name,
      config_json: {
        [manageFormData.connector_name === "email"
          ? "email_id"
          : manageFormData.connector_name === "teams"
          ? "webhook_url"
          : manageFormData.connector_name === "voice_call"
          ? "phone_number"
          : ""]: manageFormData.connector_name === "email"
          ? [emailId]
          : manageFormData.connector_name === "teams"
          ? webhookUrl
          : manageFormData.connector_name === "voice_call"
          ? phoneNumber
          : ""
      }
    });    

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: BaseUrl + '/notification-channel/',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      swal({
        title: "Channel Updated Successfully",
        text: "",
        icon: "success",
      }).then(function() {
        fetchData();
        handleCloseManageModal();
      });
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          width: '100%' 
        }}
      >
        <Button variant="contained" onClick={handleOpenModal}>Add Connectors</Button>
      </Box>
      <br />
      
      <Box
        sx={{
          height: '400px',
          overflowY: 'auto',
          paddingRight: '10px',
        }}
      >
        <Grid container spacing={2}>
        {user_connectors &&
            Object.keys(user_connectors).map((channel) => (
            <Grid item xs={2.4} sm={2.4} md={2.4} key={channel}>
                <Card
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 3,
            padding: 2,
            height: 250,
            backgroundColor: '#c8f7c8',
            borderRadius: 3,
        }}
        >
        <CardContent
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
                src={`/${user_connectors[channel].connector_name}.png`}
                alt={channel}
                style={{ width: 50, height: 50 }}
            />
            <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                {channel}
            </Typography>
            <Typography variant="body1">
                {/* <strong>Active:</strong> */}
                <Switch
                checked={user_connectors[channel].is_active}
                onChange={() => handleChange(channel, user_connectors[channel].connector_name, user_connectors[channel].channel_id, user_connectors[channel].is_active)}
                color="primary"
                name={`toggle-${channel}`}
                />
            </Typography>
            </Box>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenManageModal(user_connectors[channel].channel_id)}
            sx={{ display: 'flex' }}
            >
            Manage Connector
            </Button>
        </Box>
        </Card>

            </Grid>
            ))}
        </Grid>
      </Box>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            maxHeight: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
        }}>
            <Box 
              display="flex" 
              alignItems="center" 
              justifyContent="space-between" 
              mb={2}
            >
              <Typography variant="h6" gutterBottom>
                Add Connector
              </Typography>
              <Button onClick={handleCloseModal} variant="outlined" color="primary">
                Close
              </Button>
            </Box>
            <TextField
            label="Channel Name"
            fullWidth
            margin="normal"
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
            />
            <RadioGroup value={selectedConnector} onChange={handleConnectorChange} row>
            {['email', 'teams', 'voice_call'].map((key) => (
                <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={key === 'email' ? 'Email' :
                        key === 'teams' ? 'Teams' :
                        key === 'voice_call' ? 'Voice Call' : key.replace('_', ' ').toUpperCase()}
                />
            ))}
            {Object.keys(masterConnectors).map((key) => (
                !['email', 'teams', 'voice_call'].includes(key) && (
                <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key === 'whatsapp' ? 'WhatsApp' :
                        key === 'zoho_desk' ? 'Zoho Desk' :
                        key === 'service_now' ? 'ServiceNow' :
                        key.replace('_', ' ').toUpperCase()}
                    disabled
                />
                )
            ))}
            </RadioGroup>
            {selectedConnector && (
            <Box component="form" sx={{ mt: 2 }}>
                {masterConnectors[selectedConnector]?.required.map((field) => (
                <TextField
                    key={field}
                    fullWidth
                    label={masterConnectors[selectedConnector]?.details[field]?.desc || field}
                    name={field}
                    value={formData[field] || ''}
                    onChange={handleInputChange}
                    margin="normal"
                />
                ))}
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Add
                </Button>
            </Box>
            )}
        </Box>
      </Modal>
      
      <Modal open={isManageModalOpen} onClose={handleCloseManageModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            maxHeight: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h6" gutterBottom>
              Update Connector
            </Typography>
            <Button onClick={handleCloseManageModal} variant="outlined" color="primary">
              Close
            </Button>
          </Box>

          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              margin="normal"
              label="Channel"
              value={manageFormData.channel}
              disabled
            />
            <TextField
              fullWidth
              margin="normal"
              label={manageFormData.connector_name === "email" ? "Email" : manageFormData.connector_name === "teams" ? "Teams" : manageFormData.connector_name === "voice_call" ? "Voice Call" : ""}
              value={manageFormData.connector_name === "email" ? emailId : manageFormData.connector_name === "teams" ? webhookUrl : manageFormData.connector_name === "voice_call" ? phoneNumber : ""}
              onChange={(e) => {
                if (manageFormData.connector_name === "email") {
                  setEmailId(e.target.value);
                } else if (manageFormData.connector_name === "teams") {
                  setWebhookUrl(e.target.value);
                } else if (manageFormData.connector_name === "voice_call") {
                  setPhoneNumber(e.target.value);
                }
              }}
            />
            <Button variant="contained" color="primary" onClick={handleUpdateSubmit}>
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Notifications1;