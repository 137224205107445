import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { MenuItem, Select, Checkbox, ListItemText, FormControl, Button } from '@mui/material';
import { BaseUrl } from '../constants/constants';
import swal from 'sweetalert';

const AlertMapping = () => {
  const [rows, setRows] = useState([]);
  const [channels, setChannels] = useState({});
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken;

  const fetchAlertMapping = async () => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BaseUrl}/notification-channel/ruleid_mapping`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    try {
      const response = await axios.request(config);
      const data = response.data.alert_rule_mapping || [];
      const formattedRows = data.map((item, index) => ({
        id: index + 1,
        rule_id: Array.isArray(item.ids) ? item.ids : item.ids.split(','),
        title: item.description,
        channel_ids: item.channel_ids || [],
      }));
      setRows(formattedRows);
    } catch (error) {
      console.error('Error fetching alert mapping:', error);
    }
  };

  const fetchChannels = async () => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BaseUrl}/notification-channel/`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    try {
      const response = await axios.request(config);
      const data = response.data.user_connectors || {};
      setChannels(data);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  };

  const handleUpdate = async (row) => {
    const channelIds = row.channel_ids;
    const ruleIds = row.rule_id;

    const data = JSON.stringify({
      channel_ids: channelIds,
      rule_ids: ruleIds
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseUrl + '/notification-channel/update_ruleid_mapping',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      swal({
        title: "Alert Mapping Successful.",
        text: "",
        icon: "success",
      }).then(function() {
        fetchAlertMapping();
        fetchChannels();
      });
    } catch (error) {
      console.error('Error updating rule mapping:', error);
    }
  };

  useEffect(() => {
    fetchAlertMapping();
    fetchChannels();
  }, []);

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1, cellClassName: 'word-wrap' },
    {
      field: 'channels',
      headerName: 'Channels',
      flex: 1,
      renderCell: (params) => {
        const channelKeys = Object.keys(channels);
        return (
          <FormControl fullWidth>
            <Select
              multiple
              value={params.row.channel_ids}
              renderValue={(selected) =>
                selected
                  .map((id) => channelKeys.find((key) => channels[key].channel_id === id))
                  .join(', ')
              }
              onChange={(event) => {
                const newChannelIds = event.target.value;
                const updatedRows = rows.map((row) =>
                  row.id === params.row.id ? { ...row, channel_ids: newChannelIds } : row
                );
                setRows(updatedRows);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 400,
                  },
                },
              }}
              style={{ width: '400px', height: '40px' }}
            >
              {channelKeys.map((key) => (
                <MenuItem key={channels[key].channel_id} value={channels[key].channel_id}>
                  <Checkbox checked={params.row.channel_ids.includes(channels[key].channel_id)} />
                  <ListItemText primary={key} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    // {
    //   field: 'rule_id',
    //   headerName: 'Rule ID',
    //   flex: 1,
    //   renderCell: (params) => {
    //     const ruleId = Array.isArray(params.row.rule_id) ? params.row.rule_id : [params.row.rule_id];
    //     return <div style={{ overflowWrap: 'break-word' }}>{ruleId.join(', ')}</div>;
    //   },
    // },
    {
      field: 'update',
      flex: 0.5,
      headerName: 'Action',
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleUpdate(params.row)}
          >
            Update
          </Button>
        );
      },
    }
  ];

  // return (
  //   <div>
  //     <div style={{ height: 400, width: '100%' }}>
  //       <style>
  //         {`
  //           .MuiDataGrid-columnHeader {
  //             background-color: green;
  //             color: white;
  //           }
  //           .MuiDataGrid-cell {
  //             color: black;
  //           }
  //           .MuiDataGrid-footerContainer {
  //             background-color: lightgray;
  //           }
  //           .MuiDataGrid-row:nth-of-type(odd) {
  //             background-color: lightyellow;
  //           }
  //           .MuiDataGrid-row:nth-of-type(even) {
  //             background-color: white;
  //           }
  //         `}
  //       </style>
  //       <DataGrid rows={rows} columns={columns} pageSize={10} selectionModel={[]}/>
  //     </div>
  //   </div>
  // );
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '75vh', width: '100%' }}>
      <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          sx={{
            height: '100%',
            width: '100%',
          }}
        />
      </div>
    </div>
  );  
};

export default AlertMapping;