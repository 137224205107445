import React, { useEffect, useState } from 'react';

const Reporting = () => {
  const tenant_ktoken = localStorage.getItem("tenant_token");
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const kibanaToken = tenant_ktoken ? tenant_ktoken : user_details.data.kibana_token;
  
  const selected_tenant = localStorage.getItem("selectedtenant");
  const [tenantname, setTenantname] = useState(
    selected_tenant || JSON.parse(window.localStorage.getItem("tenant_name"))
  );
  
  const iframeSrc = `https://devsaas.khika.in/kibana/app/wz-home?security_tenant=${tenantname}&embed=true&auth_token=${kibanaToken}#/manager/?tab=reporting`;

  useEffect(() => {
    
  }, [iframeSrc]);

  return (
    <>
      <iframe 
        className='hide_menu'
        frameBorder="0"
        src={iframeSrc}
      />
    </>
  );
};

export default Reporting;