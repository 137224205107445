import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, TextField, Typography, Container, Card, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from './hooks/useFullPageLoader';
import { BaseUrl, SyslogDevices } from '../constants/constants';
import CloseIcon from '@mui/icons-material/Close'; 

const Syslog = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_role = window.localStorage.getItem('user_role');
  const user_details = JSON.parse(window.localStorage.getItem('user_details'));
  const token = user_details.data.accessToken;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [deviceType, setDeviceType] = useState('Firewall'); 
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [ipAddressError, setIpAddressError] = useState('');

  useEffect(() => {
    setOptions(deviceOptions['Firewall']);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,  
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2, 
  };

  const deviceOptions = {
    Firewall: ['Fortinet', 'Palo Alto', 'Cisco ASA', 'Sophos', 'Check Point'],
    Switch: ['Cisco', 'Juniper', 'HP ProCurve', 'Dell', 'Netgear'],
    Routers: ['Cisco', 'TP-Link', 'MikroTik', 'Juniper', 'Linksys'],
    'Anti Virus': ['Norton', 'McAfee', 'Kaspersky', 'Avast', 'Bitdefender'],
    EDR: ['CrowdStrike', 'SentinelOne', 'Carbon Black', 'Cylance', 'Trend Micro'],
  };

  const handleDeviceTypeSelect = (type) => {
    setDeviceType(type);
    setOptions(deviceOptions[type] || []);
    setSelectedOption('');
    setDeviceName(''); 
    setIpAddress(''); 
    setIpAddressError(''); 
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDeviceName(''); 
    setIpAddress(''); 
    setIpAddressError(''); 
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValidIpAddress(ipAddress)) {
      setIpAddressError('Invalid IP address');
      alert('Invalid IP Address');
    } else {
      swal({
        title: "Confirm Device Addition",
        icon: "info",
        buttons: ["Cancel", "Confirm"],
        dangerMode: false,
        content: {
          element: "span",
          attributes: {
            style: `
            font-family: Arial, sans-serif; 
            font-size: 14px; 
            text-align: center; 
            display: flex; 
            flex-direction: column; 
            align-items: center;
            justify-content: center;
          `,            
          innerHTML: `
              <div style="font-family: Arial, sans-serif; font-size: 14px; text-align: left;">
                <strong>Device Name:</strong> ${deviceName}<br>
                <strong>Device Type:</strong> ${deviceType}<br>
                <strong>Device Option:</strong> ${selectedOption}<br>
                <strong>IP Address:</strong> ${ipAddress}
              </div>
            `
          }
        }
      }).then((willAdd) => {
        if (willAdd) {
          showLoader();
          const data = JSON.stringify({
            device_name: deviceName,
            ip_address: ipAddress,
            device_tag: deviceType,
          });
  
          const config = {
            method: 'post',
            url: BaseUrl + SyslogDevices,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            data: data,
          };
  
          axios(config)
            .then((response) => {
              hideLoader();
              swal({
                title: 'Device Added Successfully',
                icon: 'success',
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              hideLoader();
              swal({
                title: error.response.data.message,
                icon: 'error',
              }).then(() => {
                window.location.reload();
              });
            });
        }
      });
    }
  };

  const isValidIpAddress = (ipAddress) => {
    const ipAddressPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipAddressPattern.test(ipAddress);
  };

  return (
    <Container>
      <Button variant="outlined" className="syslog-back-btn">
        <Link to="/dashboard/connectors" style={{ textDecoration: 'none', color: '#00AB55', textTransform: 'capitalize' }}>
          Back
        </Link>
      </Button>
      <Button variant="outlined" className="syslog-add-btn" onClick={handleOpen} disabled={user_role === 'user_ro'}>
        Add Device
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...style,
            width: 900, 
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
              Add Device
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box component="table" sx={{ width: '100%', borderCollapse: 'collapse' }}>
                  <Box component="tbody">
                    {Object.keys(deviceOptions).map((type) => (
                      <Box
                        component="tr"
                        key={type}
                        onClick={() => handleDeviceTypeSelect(type)}
                        sx={{
                          cursor: 'pointer',
                          bgcolor: deviceType === type ? 'primary.main' : '#f5f5f5',
                          '&:hover': {
                            bgcolor: 'primary.light',
                          },
                          borderBottom: '1px solid #ccc',
                          borderRadius: '8px',
                          transition: 'all 0.3s ease-in-out',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '10px',
                          marginBottom: '5px',
                          color: deviceType === type ? 'white' : 'black',
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: deviceType === type ? 'bold' : 'normal' }}>
                          {type}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={8}>
                {options.length > 0 && (
                  <>
                    <Grid container spacing={1}>
                      {options.map((option) => (
                        <Grid item key={option} xs={12} sm={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              width: '100%',
                              height: '40px',
                              padding: '8px',
                              bgcolor: selectedOption === option ? 'primary.main' : 'background.paper',
                              color: selectedOption === option ? 'white' : 'text.primary',
                              border: '1px solid',
                              borderColor: selectedOption === option ? 'primary.dark' : 'text.secondary',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease-in-out',
                              '&:hover': {
                                bgcolor: selectedOption === option ? 'primary.dark' : 'primary.light',
                              },
                              marginBottom: '8px',
                            }}
                            onClick={() => handleOptionSelect(option)}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: selectedOption === option ? 'bold' : 'normal',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {option}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                {selectedOption && (
                  <>
                    <TextField
                      id="device-name"
                      label="Device Name"
                      value={deviceName}
                      onChange={(e) => setDeviceName(e.target.value)}
                      fullWidth
                      required
                      style={{ marginTop: 16 }}
                    />
                    <TextField
                      id="device-ip"
                      label="Device IP"
                      value={ipAddress}
                      onChange={(e) => setIpAddress(e.target.value)}
                      fullWidth
                      required
                      style={{ marginTop: 16 }}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Box sx={{ textAlign: 'right', marginTop: 2 }}>
    <Button type="submit" variant="outlined" disabled={user_role === 'user_ro'}>
      Add
    </Button>
  </Box>
          </form>
        </Box>
      </Modal>

      <div className="sys-devices-table">
        <Card>
          <Box style={{ height: 450, width: '100%' }}>
            <style>
              {`
                .MuiDataGrid-columnHeader {
                  background-color: green;
                  color: white;
                }
                .MuiDataGrid-cell {
                  color: black;
                }
                .MuiDataGrid-footerContainer {
                  background-color: lightgray;
                }
                .MuiDataGrid-row:nth-of-type(odd) {
                  background-color: lightyellow;
                }
                .MuiDataGrid-row:nth-of-type(even) {
                  background-color: white;
                }
              `}
            </style>
            <DataGrid
              rows={[]}
              rowCount={0}
              loading={false}
              rowsPerPageOptions={[10]}
              rowHeight={50}
              pagination
              page={0}
              pageSize={10}
              onPageChange={() => {}}
              onPageSizeChange={() => {}}
              columns={[]}
            />
          </Box>
        </Card>
      </div>
    </Container>
  );
};

export default Syslog;
