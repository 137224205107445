import React from 'react'
import axios from 'axios';
import { BaseUrl, CloudDevices } from '../constants/constants';
import { Container } from '@mui/system';
import { Box, Button, Card, Modal, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import useFullPageLoader from './hooks/useFullPageLoader';
import swal from 'sweetalert';

const Cloud = () => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_role = window.localStorage.getItem("user_role");
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [totalRows, setTotalRows] = React.useState(0);

  const columns = [
    {
      field: 'account_id', headerName: 'Account ID', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'unique_key', headerName: 'Unique Key', flex: 0.5, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'service', headerName: 'Service', flex: 0.3, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'type', headerName: 'Type', flex: 0.3, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'cron', headerName: 'Cron', flex: 0.3, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'status', headerName: 'Status', flex: 0.3, cellClassName: (params) => {
        return 'word-wrap';
      }
    },
    {
      field: 'actions', headerName: 'Actions', flex: 0.5, renderCell: (params) => {
        return (
          <>
            <Button
              onClick={(event) => window.location.href = `/dashboard/update-cloud-account/?id=${params.row.id}`}
              variant="outlined"
              style={{ marginRight: 10 }}
              disabled={user_role === 'user_ro'}
            >
              Update
            </Button>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                deleteDevice(params.row);
              }}
              variant="outlined"
              color="error"
              disabled={user_role === 'user_ro'}
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    fetchData('');

  }, []);

  const fetchData = async page => {
    showLoader();
    setLoading(true);
    var config = {
      method: 'get',
      url: BaseUrl + CloudDevices + page,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    axios(config)
      .then(function (response) {
        hideLoader();
        setData(response.data.map(element => {
          return {
            "id": element.cloud_id,
            "type": element.type,
            "service": element.service,
            "status": element.is_active === true ? element.is_active = "Active" : element.is_active = "Inactive",
            "cron": element.config_json.cron,
            "unique_key": element.unique_key,
            "account_id": element.acc_id
          }
        }))
        setTotalRows(response.data.dev_id);
        setLoading(false);
      })
      .catch(function (error) {

      });
  }

  const [openaccountdetails, setOpenAccountDetails] = React.useState(false);
  const handleOpenAccountDetails = () => setOpenAccountDetails(true);
  const handleCloseAccountDetails = () => setOpenAccountDetails(false);

  const [accountdata, setAccountData] = React.useState({});

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const accountdatastyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleRowClick = (params) => {
    var config = {
      method: 'get',
      url: BaseUrl + CloudDevices + "/" + params.id,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    axios(config)
      .then(function (response) {
        setAccountData(response.data);
        handleOpenAccountDetails()
      })
      .catch(function (error) {

      });
  };

  const deleteDevice = (row) => {
    showLoader();
    var config = {
      method: 'delete',
      url: BaseUrl + CloudDevices + '/' + row.id,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    axios(config)
      .then(function (response) {
        hideLoader();
        swal({
          title: "Account Deleted Successfully",
          text: "",
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Container>
        <Button
          variant="outlined"
          color="info"
          className="syslog-back-btn"
          component={Link}
          to="/dashboard/connectors"
          sx={{ textTransform: "capitalize" }}
        >
          Back
        </Button>
        <Button variant='outlined' className='syslog-add-btn' disabled={user_role === 'user_ro'}>
          <Link
            to="/dashboard/add-cloud-account"
            style={{
              textDecoration: 'none',
              color: user_role === 'user_ro' ? 'grey' : '#00AB55',
              textTransform: 'capitalize'
            }}
          >
            Add Account
          </Link>
        </Button>
        <Modal
          open={openaccountdetails}
          onClose={handleCloseAccountDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={accountdatastyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Account Details
            </Typography>
            <br />
            <Typography>
              {Object.entries(accountdata).map(([key, value], index) => (
                <React.Fragment key={key}>
                  {index !== 0 && <br />}
                  {key}: {JSON.stringify(value, null, 2)}
                </React.Fragment>
              ))}
            </Typography>
            <br />
            <Button variant='outlined' onClick={handleCloseAccountDetails}>Close</Button>
          </Box>
        </Modal>
        <div className='sys-devices-table'>
          <Card>
            <Box style={{ height: 450, width: '100%' }}>
              <DataGrid
                rows={data}
                rowCount={totalRows}
                loading={loading}
                rowsPerPageOptions={[10]}
                rowHeight={50}
                pagination
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                columns={columns}
                onRowClick={handleRowClick}
                disableSelectionOnClick
              />
            </Box>
          </Card>
        </div>
      </Container>
      {loader}
    </>
  )
}

export default Cloud