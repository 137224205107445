import React, { useEffect, useState } from 'react';
import { configRule, fetchPlaybookData } from '../api/api.js';
import { Grid, Card, CardContent, Typography, IconButton, Tooltip, Button, Divider, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box, TextField, InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import useFullPageLoader from './hooks/useFullPageLoader.js';
import swal from 'sweetalert';

const Playbook1 = ({ setSelectedTab }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setData] = useState(null);
  const [category, setCategory] = useState('all');
  const [level, setLevel] = useState('notification');
  const [categorySearch, setCategorySearch] = useState('');
  const [alertSearch, setAlertSearch] = useState('');

  const getData = async () => {
    try {
      const result = await fetchPlaybookData(category, level);
      setData(result);
      hideLoader();
    } catch (err) {
      console.error(err);
      hideLoader();
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    showLoader();
    getData();
  }, [category, level]);

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const getCategoryStyle = (groupName) => {
    return groupName === category
      ? { backgroundColor: '#c3f0c0', fontWeight: 'bold' }
      : {};
  };

  const filteredCategories = data?.khika_rule_groups
    ? Object.entries(data.khika_rule_groups).filter(([groupName]) =>
        groupName.toLowerCase().includes(categorySearch.toLowerCase())
      )
    : [];

  const filteredAlerts = data?.alert_rule_mapping
    ? data.alert_rule_mapping.filter((rule) =>
        rule.khika_rule_title.toLowerCase().includes(alertSearch.toLowerCase()) ||
        rule.khika_rule_description.toLowerCase().includes(alertSearch.toLowerCase())
      )
    : [];

  const handleConfigureClick = async (khika_rule_id) => {
    showLoader();
    try {
      await configRule(khika_rule_id);
      swal({
        title: "Alert Configured Successful.",
        text: "",
        icon: "success",
      }).then(function() {
        getData();
      });
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  };    

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      
      <div
        style={{
          width: '20%',
          // backgroundColor: '#f4f4f4',
          padding: '1px',
          marginRight: '1%',
          overflowY: 'auto',
          height: '100vh',
        }}
      >
        <TextField
          label="Search Categories"
          variant="outlined"
          fullWidth
          value={categorySearch}
          onChange={(e) => setCategorySearch(e.target.value)}
          sx={{ marginBottom: '20px','& .MuiInputLabel-root': {
            lineHeight: '1.5',
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          }, }}
          InputProps={{
            endAdornment: categorySearch && (
              <InputAdornment position="end">
                <IconButton onClick={() => setCategorySearch('')} edge="end">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            padding: '5px',
            borderBottom: '1px solid #ddd',
            cursor: 'pointer',
            ...getCategoryStyle('all'),
          }}
          onClick={() => handleCategoryChange('all')}
        >
          <Typography variant="body2" sx={{ flex: 1 }}>
            All
          </Typography>
          <Typography variant="body2" sx={{ flex: 1, textAlign: 'right' }}>
            {data && data.khika_rule_groups
              ? Object.values(data.khika_rule_groups).reduce((acc, count) => acc + count, 0)
              : 0}
          </Typography>
        </div>

        {filteredCategories.length > 0 ? (
          filteredCategories.map(([groupName, count]) => (
            <div
              key={groupName}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                padding: '5px',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
                ...getCategoryStyle(groupName),
              }}
              onClick={() => handleCategoryChange(groupName)}
            >
              <Typography variant="body2" sx={{ flex: 1 }}>
                {groupName}
              </Typography>
              <Typography variant="body2" sx={{ flex: 1, textAlign: 'right' }}>
                {count}
              </Typography>
            </div>
          ))
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            No categories found
          </Typography>
        )}
      </div>

      <Divider orientation="vertical" flexItem sx={{ borderColor: 'black' }} />

      <div style={{ width: '80%', overflowY: 'auto', padding: '1px', height: '100vh', marginLeft: '1%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <TextField
            label="Search Alerts"
            variant="outlined"
            value={alertSearch}
            onChange={(e) => setAlertSearch(e.target.value)}
            sx={{ marginBottom: 0, width: '40%', '& .MuiInputLabel-root': {
              lineHeight: '1.5',
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            }, }}
            InputProps={{
              endAdornment: alertSearch && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setAlertSearch('')} edge="end">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl component="fieldset">
            <RadioGroup row value={level} onChange={handleLevelChange}>
              <Tooltip
                title="Alerts that can be configured to send notifications to external channels such as Email, Teams, Calls, etc."
                arrow
              >
                <FormControlLabel
                  value="notification"
                  control={<Radio />}
                  label={`Notification (${data?.rule_levels?.notification || 0})`}
                />
              </Tooltip>
              <Tooltip
                title="Important events displayed as Security Events in the Khika Panel. Notifications are off due to high frequency but can be enabled on demand."
                arrow
              >
                <FormControlLabel
                  value="info"
                  control={<Radio />}
                  label={`Info (${data?.rule_levels?.info || 0})`}
                />
              </Tooltip>
            </RadioGroup>
          </FormControl>
        </Box>
        {filteredAlerts.length > 0 ? (
          <Grid container spacing={2}>
            {filteredAlerts.map((rule, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ height: 150, border: '1px solid #ccc' }}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title={rule.khika_rule_title} arrow>
                        <Typography
                          variant="h6"
                          sx={{
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            maxHeight: '3em',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {rule.khika_rule_title}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={rule.khika_rule_description} arrow>
                        <IconButton
                          sx={{ padding: 0, color: 'info.main', fontSize: 30 }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    {rule.level === 'notification' && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: 'auto',
                        }}
                      >
                        {rule.is_active ? (
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={() => setSelectedTab(2)}
                          >
                            Manage Alert
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleConfigureClick(rule.khika_rule_id)}
                          >
                            Configure
                          </Button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            No alerts found
          </Typography>
        )}
      </div>
      {loader}
    </div>
  );
};

export default Playbook1;