import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container, Grid, Typography, Avatar, Stack, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { getUserDetails } from '../service/UserDetails';
import { useEffect, useState } from 'react';
import { getTenantDetails } from '../service/TenantDetails';

export default function Profile() {
  const [userDetails, setUserDetails] = useState();
  const [tenantDetails, setTenantDetails] = useState();
  const [apiCall, setApiCall] = useState(false);

  useEffect(() => {
    setApiCall(true);
    getUserDetails()
      .then(resp => {
        setUserDetails(resp);
        setApiCall(false);
      });
  }, []);

  useEffect(() => {
    setApiCall(true);
    getTenantDetails()
      .then(resp => {
        setTenantDetails(resp);
        setApiCall(false);
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f7f9fc', 
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: '#fff',
          borderRadius: 3,
          padding: 4,
          boxShadow: 12,
          overflow: 'hidden',
          height: 'auto', 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          animation: 'fadeIn 1s ease-out', 
        }}
      >
        {apiCall ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Skeleton variant="circle" width={100} height={100} />
            <Skeleton width="70%" sx={{ mt: 2 }} />
            <Skeleton width="80%" sx={{ mt: 1 }} />
            <Skeleton width="90%" sx={{ mt: 1 }} />
          </Box>
        ) : (
          <Stack direction="column" spacing={3} alignItems="center">
            <Avatar
              sx={{
                width: 120,
                height: 120,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                border: '3px solid #f5f5f5', 
              }}
              src={userDetails?.profile_picture || '/default-avatar.png'}
            />
            <Typography variant="h4" color="primary" fontWeight="bold" sx={{ mt: 2, textAlign: 'center' }}>
              {userDetails?.first_name} {userDetails?.last_name}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
              {userDetails?.email}
            </Typography>
            
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={2} alignItems="center">
                {/* Company Name */}
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Company Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {userDetails?.company_name}
                  </Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Tenant
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {tenantDetails?.tenant_name}
                  </Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Phone
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {userDetails?.phone}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Last Login
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {userDetails?.authentication?.last_login}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            
            <Divider sx={{ width: '100%', mt: 4 }} />
          </Stack>
        )}
      </Container>
    </Box>
  );
}
