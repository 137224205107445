import React, { useState, useEffect } from 'react';

const Modules = () => {
  const selected_tenant = localStorage.getItem("selectedtenant");
  const [tenantname, setTenantName] = useState(
    selected_tenant || JSON.parse(window.localStorage.getItem("tenant_name"))
  );

  const url = window.location.href;
  const urlSegments = url.split('/');
  const module = urlSegments[urlSegments.length - 1];

  const tenant_ktoken = localStorage.getItem("tenant_token");

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const kibanaToken = tenant_ktoken ? tenant_ktoken : user_details.data.kibana_token;

  const [moduleurl, setModuleUrl] = useState('');
  
  useEffect(() => {
    switch(module) {
      case "security-event":
        setModuleUrl("#/overview/?tabView=panels&tab=general&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "integrity-monitoring":
        setModuleUrl("#/overview/?tabView=panels&tab=fim&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.groups,negate:!f,params:(query:syscheck),removable:!f,type:phrase),query:(match:(rule.groups:(query:syscheck,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "policy-monitoring":
        setModuleUrl("#/overview/?tabView=panels&tab=pm&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.groups,negate:!f,params:(query:rootcheck),removable:!f,type:phrase),query:(match:(rule.groups:(query:rootcheck,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "system-auditing":
        setModuleUrl("#/overview/?tabView=panels&tab=audit&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.groups,negate:!f,params:(query:audit),removable:!f,type:phrase),query:(match:(rule.groups:(query:audit,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "security-configuration":
        setModuleUrl("#/overview/?tabView=inventory&tab=sca&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.groups,negate:!f,params:(query:sca),removable:!f,type:phrase),query:(match:(rule.groups:(query:sca,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "vulnerabilities":
        setModuleUrl("#/overview/?tabView=inventory&tab=vuls&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.groups,negate:!f,params:(query:vulnerability-detector),removable:!f,type:phrase),query:(match:(rule.groups:(query:vulnerability-detector,type:phrase))))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "mitre-attack":
        setModuleUrl("#/overview/?tabView=panels&tab=mitre&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),exists:(field:rule.mitre.id),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.mitre.id,negate:!f,removable:!f,type:exists,value:exists))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "pci-dss":
        setModuleUrl("#/overview/?tabView=panels&tab=pci&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),exists:(field:rule.pci_dss),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.pci_dss,negate:!f,removable:!f,type:exists,value:exists))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      case "nist-800-53":
        setModuleUrl("#/overview/?tabView=panels&tab=nist&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:manager.name,negate:!f,params:(query:khika2-dev-server),removable:!f,type:phrase),query:(match:(manager.name:(query:khika2-dev-server,type:phrase)))),('$state':(isImplicit:!t,store:appState),exists:(field:rule.nist_800_53),meta:(alias:!n,disabled:!f,index:'khika-alerts-*',key:rule.nist_800_53,negate:!f,removable:!f,type:exists,value:exists))),index:'khika-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())");
        break;
      // case "tsc":
      //   setModuleUrl("/tsc");
      //   break;
      // case "gdpr":
      //   setModuleUrl("/gdpr");
      //   break;
      // case "hipaa":
      //   setModuleUrl("/hipaa");
      //   break;
      default:
        setModuleUrl("");
    }
  }, [module]);

  return (
    <>
      <iframe 
        className='hide_menu'
        frameBorder="0"
        src={`https://devsaas.khika.in/kibana/app/wazuh?security_tenant=${tenantname}&embed=true&auth_token=${kibanaToken}${moduleurl}`}
        key={`https://devsaas.khika.in/kibana/app/wazuh?security_tenant=${tenantname}&embed=true&auth_token=${kibanaToken}${moduleurl}`}
      />
    </>
  );
}

export default Modules;