import React, { useState } from 'react'
import Navbar from './Navbar'
import axios, * as others from 'axios';
import { Card, Box, Button, Typography, Checkbox, FormControlLabel, Menu, Tooltip } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';  
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { AlertsList, BaseUrl } from '../constants/constants';

const Alerts = () => {
    const handleOpen = (response) => {
        setSelectedResponse(response);
        setOpen(true);
      };
      
  
  const columns = [
    { 
        field: 'timestamp', 
        headerName: 'Date Time',  
        flex: 0.6,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    { 
        field: 'agent_name', 
        headerName: 'Agent Name',  
        flex: 0.4,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    { 
        field: 'ruleid', 
        headerName: 'Rule Id', 
        flex: 0.2,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    { 
        field: 'rule_info', 
        headerName: 'Rule Info',  
        flex: 0.5,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    { 
        field: 'level', 
        headerName: 'Level', 
        flex: 0.4,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    { 
        field: 'description', 
        headerName: 'Description',  
        flex: 1,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <div>{params.value}</div>
            </Tooltip>
        )
    },
    {
        field: 'viewDetails',
        headerName: 'View Details',
        width: 150,
        renderCell: (params) => (
          <Button variant="contained" size="small" onClick={() => handleOpen(params.row)}>
            View Details
          </Button>
        ),
      }
  ];

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details.data.accessToken
  
  const now = new Date().toISOString();

  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);

  React.useEffect(() => {
		fetchData('');
		
	}, []);

  const [filterjson, setFilterJson] = useState(
    {
      "collapse": { "field": "rule.id" },
      "size":1000,
       "sort": [
                {"timestamp": "desc"}
        ],
        "query": {
          "bool": {
            "filter": 
            [
                // {"range": {"rule.level": {"gte": 8, "lt": 12}}}, 
                {"range": {
              "timestamp": {
              "gte": startdate,
              "lt": enddate
              }
            }}
            ]
          }
        }
      }
  );

  // const fetchData = async page => {
  //   setLoading(true);
  //   var data = JSON.stringify(filterjson);

  //   var config = {
  //     method: 'post',
  //     url: BaseUrl + AlertsList + page,
  //     headers: { 
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };
    
  //   axios(config)
  //   .then(function (response) {
  //     console.log(response);
  //     setData(response.data.hits.hits.map(element => {
  //       const timestamp = new Date(element._source.timestamp).toLocaleString();
  //       return {
  //         "id" : element._source.id,
  //         "timestamp" : timestamp,
  //         "agent_name":element._source.agent.name,
  //         "description" : element._source.rule.description,
  //         "level" : element._source.rule.level,
  //         "ruleid" : element._source.rule.id,
  //         "rule_info" : element._source.rule.mitre?.technique
  //       }
  //     }))
  //     setTotalRows(response.data.hits.hits.length);
  //     setLoading(false);
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // }
  const fetchData = async page => {
    setLoading(true);
    var data = JSON.stringify(filterjson);
  
    var config = {
      method: 'post',
      url: BaseUrl + AlertsList + page,
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
  
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name": element._source.agent?.name || '',
          "description" : element._source.rule?.description || '',
          "level" : element._source.rule?.level || '',
          "ruleid" : element._source.rule?.id || '',
          "rule_info" : element._source.rule?.mitre?.technique || ''
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  }   

const [dropdownlist, setDropDownList] = useState([]);

  React.useEffect(() => {
    dropdownData('');
    
}, []);

const [dropdownjson, setDropdownJson] = useState(
    {
        "aggs" : {
            "components" : {
                "terms" : { "field" : "agent.name", "size":10000 }
            }
        },
        "size" : 0
    }    
);

const dropdownData = async page => {

var data = JSON.stringify(dropdownjson);

var config = {
  method: 'post',
  url: BaseUrl + AlertsList + page,
  headers: { 
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  setDropDownList(response.data.aggregations.components.buckets);
})
.catch(function (error) {
  console.log(error);
});
}

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const TodayButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };
    return <MenuItem onClick={handleClick}>Today</MenuItem>;
  };

  const ThisWeek = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const thisWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(thisWeek);
      setEndDate(now)
      updateTimeDate(thisWeek);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>This Week</MenuItem>;
  };

  const Last15MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last15Minutes = new Date(now.getTime() - 15 * 60 * 1000);
      setStartDate(last15Minutes);
      setEndDate(now)
      updateTimeDate(last15Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 15 Mins</MenuItem>;
  };

  const Last30MinsButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Minutes = new Date(now.getTime() - 30 * 60 * 1000);
      setStartDate(last30Minutes);
      setEndDate(now)
      updateTimeDate(last30Minutes);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 30 Mins</MenuItem>;
  };

  const Last1Hour = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Hour = new Date(now.getTime() - 1 * 60 * 60 * 1000);
      setStartDate(last1Hour);
      setEndDate(now)
      updateTimeDate(last1Hour);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 1 Hour</MenuItem>;
  };

  const Last24Hours = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      setStartDate(last24Hours);
      setEndDate(now)
      updateTimeDate(last24Hours);
      handleClose();
    };

    return <MenuItem onClick={handleClick}>Last 24 Hours</MenuItem>;
  };

  const Last7DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      date.setHours(0, 0, 0, 0);
      setStartDate(date);
      setEndDate(now)
      updateTimeDate(date);
      handleClose();
    };
  
    return <MenuItem onClick={handleClick}>Last 7 Days</MenuItem>;
  };

  const Last30DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      setStartDate(last30Days);
      setEndDate(now)
      updateTimeDate(last30Days);
      handleClose();
    };
  
    return <MenuItem onClick={handleClick}>Last 30 Days</MenuItem>;
  };

  const Last90DaysButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last90Days = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
      setStartDate(last90Days);
      setEndDate(now)
      updateTimeDate(last90Days);
      handleClose();
    };
  
    return <MenuItem onClick={handleClick}>Last 90 Days</MenuItem>;
  };

  const Last1YearButton = ({ setStartDate }) => {
    const handleClick = () => {
      const now = new Date();
      const last1Year = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
      setStartDate(last1Year);
      setEndDate(now)
      updateTimeDate(last1Year);
      handleClose();
    };
  
    return <MenuItem onClick={handleClick}>Last 1 Year</MenuItem>;
  };

  // const updateTimeDate = (start) => {
  //   const selected_location = location[0];

  //   const newJsonWithLoc = {
  //     "collapse": { "field": "rule.id" },
  //     "size":1000,
  //      "sort": [
  //               {"timestamp": "desc"}
  //           ],
  //       "query": {
            
  //         "bool": {
  //           "filter": [
  //             { "match_phrase" : { "agent.name" : selected_location}} ,
  //             {  "range": {
  //               "timestamp": {
  //                 "gte": start,
  //                 "lt": enddate
  //                 }
  //               }
  //             }
  //           ]
  //         }
  //       }
  //     };
      
  //     const newJsonWithoutLoc = {
  //       "collapse": { "field": "rule.id" },
  //       "size":1000,
  //        "sort": [
  //                 {"timestamp": "desc"}
  //             ],
  //         "query": {
              
  //           "bool": {
  //             "filter": [
  //               {  "range": {
  //                 "timestamp": {
  //                   "gte": start,
  //                   "lt": enddate
  //                   }
  //                 }
  //               }
  //             ]
  //           }
  //         }
  //       };

  //     setLoading(true);
  //   var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);

  //   var config = {
  //     method: 'post',
  //     url: BaseUrl + AlertsList,
  //     headers: { 
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };
    
  //   axios(config)
  //   .then(function (response) {
  //     setData(response.data.hits.hits.map(element => {
  //       const timestamp = new Date(element._source.timestamp).toLocaleString();
  //       return {
  //         "id" : element._source.id,
  //         "timestamp" : timestamp,
  //         "agent_name":element._source.agent.name,
  //         "techniques" : element._source.rule.mitre?.id,
  //         "tactics" : element._source.rule.mitre?.tactic,
  //         "description" : element._source.rule.description,
  //         "level" : element._source.rule.level,
  //         "ruleid" : element._source.rule.id
  //       }
  //     }))
  //     setTotalRows(response.data.hits.hits.length);
  //     setLoading(false);
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  //   setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  // }
  const updateTimeDate = (start) => {
    const selected_location = location[0];
  
    const newJsonWithLoc = {
      "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{"timestamp": "desc"}],
      "query": {
        "bool": {
          "filter": [
            { "match_phrase" : { "agent.name" : selected_location } },
            { "range": { "timestamp": { "gte": start, "lt": enddate } } }
          ]
        }
      }
    };
  
    const newJsonWithoutLoc = {
      "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{"timestamp": "desc"}],
      "query": {
        "bool": {
          "filter": [
            { "range": { "timestamp": { "gte": start, "lt": enddate } } }
          ]
        }
      }
    };
  
    setLoading(true);
    var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  
    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
  
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name": element._source.agent?.name || '',
          "description" : element._source.rule?.description || '',
          "level" : element._source.rule?.level || '',
          "ruleid" : element._source.rule?.id || '',
          "rule_info" : element._source.rule?.mitre?.technique || ''
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  
    setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  }  

  // const updateDate = () => {
  //   const selected_location = location[0];

  //   const newJsonWithLoc = {
  //     "collapse": { "field": "rule.id" },
  //     "size":1000,
  //      "sort": [
  //               {"timestamp": "desc"}
  //           ],
  //       "query": {
            
  //         "bool": {
  //           "filter": [
  //             { "match_phrase" : { "agent.name" : selected_location}} ,
  //             {  "range": {
  //               "timestamp": {
  //                 "gte": startdate,
  //                 "lt": enddate
  //                 }
  //               }
  //             }
  //           ]
  //         }
  //       }
  //     };
      
  //     const newJsonWithoutLoc = {
  //       "collapse": { "field": "rule.id" },
  //       "size":1000,
  //        "sort": [
  //                 {"timestamp": "desc"}
  //             ],
  //         "query": {
              
  //           "bool": {
  //             "filter": [
  //               {  "range": {
  //                 "timestamp": {
  //                   "gte": startdate,
  //                   "lt": enddate
  //                   }
  //                 }
  //               }
  //             ]
  //           }
  //         }
  //       };

  //     setLoading(true);
  //   var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);

  //   var config = {
  //     method: 'post',
  //     url: BaseUrl + AlertsList,
  //     headers: { 
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };
    
  //   axios(config)
  //   .then(function (response) {
  //     setData(response.data.hits.hits.map(element => {
  //       const timestamp = new Date(element._source.timestamp).toLocaleString();
  //       return {
  //         "id" : element._source.id,
  //         "timestamp" : timestamp,
  //         "agent_name":element._source.agent.name,
  //         "techniques" : element._source.rule.mitre?.id,
  //         "tactics" : element._source.rule.mitre?.tactic,
  //         "description" : element._source.rule.description,
  //         "level" : element._source.rule.level,
  //         "ruleid" : element._source.rule.id
  //       }
  //     }))
  //     setTotalRows(response.data.hits.hits.length);
  //     setLoading(false);
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  //   setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  // }
  const updateDate = () => {
    const selected_location = location[0];
  
    const newJsonWithLoc = {
      "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{"timestamp": "desc"}],
      "query": {
        "bool": {
          "filter": [
            { "match_phrase" : { "agent.name" : selected_location } },
            { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
          ]
        }
      }
    };
  
    const newJsonWithoutLoc = {
      "collapse": { "field": "rule.id" },
      "size": 1000,
      "sort": [{"timestamp": "desc"}],
      "query": {
        "bool": {
          "filter": [
            { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
          ]
        }
      }
    };
  
    setLoading(true);
    var data = JSON.stringify(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  
    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
  
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name": element._source.agent?.name || '',
          "description" : element._source.rule?.description || '',
          "level" : element._source.rule?.level || '',
          "ruleid" : element._source.rule?.id || '',
          "rule_info" : element._source.rule?.mitre?.technique || ''
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  
    setFilterJson(selected_location ? newJsonWithLoc : newJsonWithoutLoc);
  }  

  const resetDate = () => {
    const newJson = {
      "collapse": { "field": "rule.id" },
      "size":1000,
       "sort": [
                {"timestamp": "desc"}
        ],
        "query": {
          "bool": {
            "filter": 
            [
                // {"range": {"rule.level": {"gte": 8, "lt": 12}}}, 
                {"range": {
              "timestamp": {
              "gte": startdate,
              "lt": enddate
              }
            }}
            ]
          }
        }
      };
      
      setLoading(true);
    var data = JSON.stringify(newJson);

    var config = {
      method: 'post',
      url: BaseUrl + AlertsList,
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setData(response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          "id" : element._source.id,
          "timestamp" : timestamp,
          "agent_name":element._source.agent?.name || '',
          "techniques" : element._source.rule?.mitre?.id || '',
          "tactics" : element._source.rule?.mitre?.tactic || '',
          "description" : element._source.rule?.description || '',
          "level" : element._source.rule?.level || '',
          "ruleid" : element._source.rule?.id || ''
        }
      }))
      setTotalRows(response.data.hits.hits.length);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
    setFilterJson(newJson);
    setLocation([]);
    setStartDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
    setEndDate(new Date().toISOString());
  }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

    const names = dropdownlist.map(item => (item.key))

    function getStyles(name, location, theme) {
    return {
        fontWeight:
        location.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
    }

  const theme = useTheme();
  const [location, setLocation] = React.useState([]);
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocation(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [levelgreaterthan12, setLevelGreaterThan12] = useState(false);
  const [levelbetween8to12, setLevelBetween8to12] = useState(false);

  function handlelevelgreaterthan12Checkbox(event) {
    if(setLevelBetween8to12){
      setLevelBetween8to12(false)
    }
    setLevelGreaterThan12(event.target.checked);
    if (event.target.checked) {
      const newJsonWithLoc = {
        "collapse": { "field": "rule.id" },
        "size":1000,
        "sort": [
          {"timestamp": "desc"}
        ],
        "query": {          
          "bool": {
            "filter": [
              {
                "range": {
                  "rule.level": {
                    "gte": 12
                  }
                }
              },
              { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
            ]
          }
        }
      };
      setLoading(true);
      var data = JSON.stringify(newJsonWithLoc);
      
      var config = {
        method: 'post',
        url: BaseUrl + AlertsList,
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            "id" : element._source.id,
            "timestamp" : timestamp,
            "agent_name":element._source.agent.name,
            "techniques" : element._source.rule.mitre?.id,
            "tactics" : element._source.rule.mitre?.tactic,
            "description" : element._source.rule.description,
            "level" : element._source.rule.level,
            "ruleid" : element._source.rule.id
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
      setFilterJson(newJsonWithLoc);
    }else{
      resetDate()
    }
  }

  function handlelevelbetween8to12Checkbox(event) {
    if(setLevelGreaterThan12){
      setLevelGreaterThan12(false)
    }
    setLevelBetween8to12(event.target.checked);
    if (event.target.checked) {
      const newJsonWithLoc = {
        "collapse": { "field": "rule.id" },
        "size":1000,
        "sort": [
          {"timestamp": "desc"}
        ],
        "query": {          
          "bool": {
            "filter": [
              {
                "range": {
                  "rule.level": {
                    "gte": 8, "lte": 12
                  }
                }
              },
              { "range": { "timestamp": { "gte": startdate, "lt": enddate } } }
            ]
          }
        }
      };
      setLoading(true);
      var data = JSON.stringify(newJsonWithLoc);
      
      var config = {
        method: 'post',
        url: BaseUrl + AlertsList,
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
      .then(function (response) {
        setData(response.data.hits.hits.map(element => {
          const timestamp = new Date(element._source.timestamp).toLocaleString();
          return {
            "id" : element._source.id,
            "timestamp" : timestamp,
            "agent_name":element._source.agent.name,
            "techniques" : element._source.rule.mitre?.id,
            "tactics" : element._source.rule.mitre?.tactic,
            "description" : element._source.rule.description,
            "level" : element._source.rule.level,
            "ruleid" : element._source.rule.id
          }
        }))
        setTotalRows(response.data.hits.hits.length);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
      setFilterJson(newJsonWithLoc);
    }else{
      resetDate()
    }
  }

  return (
    <>
    <div className='date-picker'>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
      size={'small'}
        renderInput={(props) => <TextField {...props} size={'small'}/>}
        label="Start Date"
        value={startdate}
        onChange={(newValue) => {
          setStartDate(newValue);
        }}
      />
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
      size={'small'}
        renderInput={(props) => <TextField {...props} size={'small'}/>}
        label="End Date"
        value={enddate}
        onChange={(newValue) => {
          setEndDate(newValue);
        }}
      />
    </LocalizationProvider>
    </div>
    <div className='apply-btn'>
      <Button variant="contained" onClick={updateDate} size={'small'}>
        Apply
      </Button>
    </div>
    <div className='reset-btn'>
      <Button variant="contained" onClick={resetDate} size={'small'}>
        Reset
      </Button>
    </div>
    <div className='location-dropdown'>
      <FormControl sx={{ m: 1, width: 250 }} size={'small'}>
        <InputLabel id="demo-multiple-chip-label" size={'small'}>Agents</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          size={'small'}
          value={location}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} size={'small'}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, location, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    <div className='alerts-checkbox'>
    <FormControlLabel
    size={'small'}
      control={<Checkbox
        checked={levelgreaterthan12}
        onChange={handlelevelgreaterthan12Checkbox}
        size={'small'}
         />}
      label="Critical Alerts"
    />
    <FormControlLabel
    size={'small'}
      control={<Checkbox 
        checked={levelbetween8to12}
        size={'small'}
        onChange={handlelevelbetween8to12Checkbox}
      />}
      label="Warning Alerts"
    />
    <br />
    </div>
    <div className='quick-select'>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        Quick Select
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <TodayButton setStartDate={setStartDate} />
        <ThisWeek setStartDate={setStartDate} />
        <Last15MinsButton setStartDate={setStartDate} />
        <Last30MinsButton setStartDate={setStartDate} />
        <Last1Hour setStartDate={setStartDate} />
        <Last24Hours setStartDate={setStartDate} />
        <Last7DaysButton setStartDate={setStartDate} />
        <Last30DaysButton setStartDate={setStartDate} />
        <Last90DaysButton setStartDate={setStartDate} />
        <Last1YearButton setStartDate={setStartDate} />
      </Menu>
    </div>
    <Card>
    
        <Box  style={{ height: 420, width: '100%' }}>
          <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
      <DataGrid
        rows={data}
        rowCount={totalRows}
        loading={loading}
        rowsPerPageOptions={[10]}
        rowHeight={30}
        pagination
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        columns={columns}
      />
    </Box>
    </Card>
    </>
  )
}

export default Alerts