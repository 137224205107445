import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as React from 'react';
import logo from '../images/khikalogo.png';
import { Link, useLocation } from "react-router-dom";
import AccountPopover from '../components/AccountPopover';

const Navbar = (props) => {
  const tenantdetails = JSON.parse(window.localStorage.getItem("tenant_details"));
  const user_role = tenantdetails.authentication.role

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState({});
  const location = useLocation();

  const handleMenuClick = (event, menuName) => {
    setAnchorEl({ [menuName]: event.currentTarget });
    setSubMenuAnchorEl({});
  };

  const handleMenuClose = (menuName) => {
    setAnchorEl((prev) => ({ ...prev, [menuName]: null }));
    setSubMenuAnchorEl({});
  };

  const handleSubMenuClick = (event, subMenuName) => {
    setSubMenuAnchorEl({ [subMenuName]: event.currentTarget });
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl({});
  };

  const handleSubMenuItemClick = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl({});
  };

  const isActive = (path) => location.pathname === path;

  const isMenuActive = (paths, menuName) => {

    return (
      paths.some((path) => location.pathname.startsWith(path)) ||
      Boolean(anchorEl?.[menuName]) ||
      Boolean(subMenuAnchorEl?.[menuName])
    );
  };



  let user = props.UserData;

  const tenantname = JSON.parse(window.localStorage.getItem("tenant_name"));

  return (
    <>
      <AppBar style={{ background: 'white' }} position="sticky" sx={{ flexGrow: 1 }}>
        <Toolbar style={{ background: 'white' }}>
          <Typography sx={{ flexGrow: 1 }}>
            <img src={logo} className="headers-logo" alt="logo" sx={{ flexGrow: 1 }} />
          </Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 0.5,
            marginLeft: 'auto',
            paddingRight: 2,
          }}>

            {
              user_role != 'user_soc' ?
                <Button
                  style={{
                    color: isActive("/dashboard/home") ? 'white' : 'black',
                    fontWeight: 'bold'
                  }}
                  variant={isActive("/dashboard/home") ? "contained" : "text"}
                >
                  <Link
                    reloadDocument
                    to="/dashboard/home"
                    style={{
                      textDecoration: 'none',
                      color: isActive("/dashboard/home") ? 'white' : 'black',
                      textTransform: 'capitalize'
                    }}
                  >
                    Home
                  </Link>
                </Button>
                :
                <Button
                  style={{
                    color: isActive("/dashboard/soc") ? 'white' : 'black',
                    fontWeight: 'bold'
                  }}
                  variant={isActive("/dashboard/soc") ? "contained" : "text"}
                >
                  <Link
                    reloadDocument
                    to="/dashboard/soc"
                    style={{
                      textDecoration: 'none',
                      color: isActive("/dashboard/soc") ? 'white' : 'black',
                      textTransform: 'capitalize'
                    }}
                  >
                    Home
                  </Link>
                </Button>
            }

            {
              user_role != 'user_soc' ?
                <Button
                  style={{
                    color: isActive("/dashboard/alerts") ? 'white' : 'black',
                    fontWeight: 'bold'
                  }}
                  variant={isActive("/dashboard/alerts") ? "contained" : "text"}
                >
                  <Link
                    reloadDocument
                    to="/dashboard/alerts"
                    style={{
                      textDecoration: 'none',
                      color: isActive("/dashboard/alerts") ? 'white' : 'black',
                      textTransform: 'capitalize'
                    }}
                  >
                    Alerts
                  </Link>
                </Button>
                :
                <Button
                  style={{
                    color: isActive("/dashboard/alerts") ? 'white' : 'black',
                    fontWeight: 'bold'
                  }}
                  variant={isActive("/dashboard/alerts") ? "contained" : "text"}
                >
                  <Link
                    reloadDocument
                    to="/dashboard/alerts"
                    style={{
                      textDecoration: 'none',
                      color: isActive("/dashboard/alerts") ? 'white' : 'black',
                      textTransform: 'capitalize'
                    }}
                  >
                    Alerts
                  </Link>
                </Button>
            }

            {(localStorage.getItem("selectedtenant") || user_role !== 'user_soc') && (
              <Button
                style={{
                  color: isMenuActive([
                    "/dashboard/modules/security-management/security-event",
                    "/dashboard/modules/security-management/integrity-monitoring",
                    "/dashboard/modules/auditing-policy-monitoring/policy-monitoring",
                    "/dashboard/modules/auditing-policy-monitoring/system-auditing",
                    "/dashboard/modules/threat-detection/vulnerabilities",
                    "/dashboard/modules/threat-detection/mitre-attack",
                    "/dashboard/modules/regulatory-compliance/pci-dss",
                    "/dashboard/modules/regulatory-compliance/nist-800-53",
                  ])
                    ? "white"
                    : "black",
                  fontWeight: "bold",
                }}
                onClick={(e) => handleMenuClick(e, "modules")}
                variant={
                  isMenuActive([
                    "/dashboard/modules/security-management/security-event",
                    "/dashboard/modules/security-management/integrity-monitoring",
                    "/dashboard/modules/auditing-policy-monitoring/policy-monitoring",
                    "/dashboard/modules/auditing-policy-monitoring/system-auditing",
                    "/dashboard/modules/threat-detection/vulnerabilities",
                    "/dashboard/modules/threat-detection/mitre-attack",
                    "/dashboard/modules/regulatory-compliance/pci-dss",
                    "/dashboard/modules/regulatory-compliance/nist-800-53",
                    "/dashboard/modules/regulatory-compliance/tsc",
                  ])
                    ? "contained"
                    : "text"
                }
              >
                Modules <ExpandMoreIcon />
              </Button>
            )}

            <Menu
              anchorEl={anchorEl?.modules}
              open={Boolean(anchorEl?.modules)}
              onClose={() => handleMenuClose("modules")}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem onClick={(e) => handleSubMenuClick(e, "securityInfoManagement")}>
                Security Information Management <ChevronRightIcon />
              </MenuItem>
              <Menu
                anchorEl={subMenuAnchorEl?.securityInfoManagement}
                open={Boolean(subMenuAnchorEl?.securityInfoManagement)}
                onClose={() => handleMenuClose("securityInfoManagement")}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/security-management/security-event"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Security Events
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/security-management/integrity-monitoring"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Integrity Monitoring
                  </Link>
                </MenuItem>
              </Menu>

              <MenuItem onClick={(e) => handleSubMenuClick(e, "auditingPolicyMonitoring")}>
                Auditing and Policy Monitoring <ChevronRightIcon />
              </MenuItem>
              <Menu
                anchorEl={subMenuAnchorEl?.auditingPolicyMonitoring}
                open={Boolean(subMenuAnchorEl?.auditingPolicyMonitoring)}
                onClose={() => handleMenuClose("auditingPolicyMonitoring")}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/auditing-policy-monitoring/policy-monitoring"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Policy Monitoring
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/auditing-policy-monitoring/system-auditing"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    System Auditing
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/auditing-policy-monitoring/security-configuration"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    CIS Benchmark (SCA)
                  </Link>
                </MenuItem>
              </Menu>

              <MenuItem onClick={(e) => handleSubMenuClick(e, "threatDetection")}>
                Threat Detection and Response <ChevronRightIcon />
              </MenuItem>
              <Menu
                anchorEl={subMenuAnchorEl?.threatDetection}
                open={Boolean(subMenuAnchorEl?.threatDetection)}
                onClose={() => handleMenuClose("threatDetection")}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/threat-detection/vulnerabilities"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Vulnerabilities
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/threat-detection/mitre-attack"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    MITRE ATT&CK
                  </Link>
                </MenuItem>
              </Menu>

              <MenuItem onClick={(e) => handleSubMenuClick(e, "regulatoryCompliance")}>
                Regulatory Compliance <ChevronRightIcon />
              </MenuItem>
              <Menu
                anchorEl={subMenuAnchorEl?.regulatoryCompliance}
                open={Boolean(subMenuAnchorEl?.regulatoryCompliance)}
                onClose={() => handleMenuClose("regulatoryCompliance")}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/regulatory-compliance/pci-dss"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    PCI DSS
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSubMenuItemClick}>
                  <Link
                    to="/dashboard/modules/regulatory-compliance/nist-800-53"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    NIST 800-53
                  </Link>
                </MenuItem>
                {/* <MenuItem onClick={handleSubMenuItemClick}>
                  <Link to="/dashboard/modules/regulatory-compliance/tsc" style={{ textDecoration: "none", color: "black" }}>
                    TSC
                  </Link>
                </MenuItem> */}
                {/* <MenuItem onClick={handleSubMenuItemClick}>
                  <Link to="/dashboard/modules/regulatory-compliance/gdpr" style={{ textDecoration: "none", color: "black" }}>
                    GDPR
                  </Link>
                </MenuItem> */}
                {/* <MenuItem onClick={handleSubMenuItemClick}>
                  <Link to="/dashboard/hipaa" style={{ textDecoration: "none", color: "black" }}>
                    HIPAA
                  </Link>
                </MenuItem> */}
              </Menu>
            </Menu>

            {(localStorage.getItem("selectedtenant") || user_role !== 'user_soc') &&(
            <Button
              style={{
                color: isMenuActive(['/dashboard/alertdiscover', '/dashboard/archivediscover', '/dashboard/cis', '/dashboard/mis']) ? 'white' : 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize'
              }}
              onClick={(event) => handleSubMenuClick(event, 'dataInsights')}
              variant={isMenuActive(['/dashboard/alertdiscover', '/dashboard/archivediscover', '/dashboard/cis', '/dashboard/mis']) ? "contained" : "text"}
            >
              Data Insights
              <ExpandMoreIcon />
            </Button>
            )}
            <Menu
              anchorEl={subMenuAnchorEl.dataInsights}
              open={Boolean(subMenuAnchorEl.dataInsights)}
              onClose={() => handleSubMenuClose('dataInsights')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/alertdiscover" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  Security Alert Logs
                </Link>
              </MenuItem>
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/archivediscover" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  Raw Logs
                </Link>
              </MenuItem>
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/cis" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  CIS Benchmark
                </Link>
              </MenuItem>
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/misp" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  Threat Intel Overview
                </Link>
              </MenuItem>
              {tenantname === "a2hpa2etmte5" && (
                <>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/dashboard/mlsearch" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                      Data Insights
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/dashboard/logsearch" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                      Log Search
                    </Link>
                  </MenuItem>
                  <Button style={{ color: 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
                    <Link to="/dashboard/chatbot" style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }}>AI Troubleshoot</Link>
                  </Button>
                </>
              )}
            </Menu>

            {(localStorage.getItem("selectedtenant") || user_role !== 'user_soc') &&(
            <Button
              style={{
                color: isMenuActive(['/dashboard/reporting', '/dashboard/generate_reports']) ? 'white' : 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize'
              }}
              onClick={(event) => handleSubMenuClick(event, 'reporting')}
              variant={isMenuActive(['/dashboard/reporting', '/dashboard/generate_reports']) ? "contained" : "text"}
            >
              Reports
              <ExpandMoreIcon />
            </Button>
            )}
            <Menu
              anchorEl={subMenuAnchorEl.reporting}
              open={Boolean(subMenuAnchorEl.reporting)}
              onClose={() => handleSubMenuClose('reporting')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/reporting" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  Module Reports
                </Link>
              </MenuItem>
              <MenuItem onClick={handleSubMenuClose}>
                <Link to="/dashboard/generate_reports" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                  Generate Reports
                </Link>
              </MenuItem>
            </Menu>

            {
              user_role != 'user_soc' ?
                <>
                  <Button
                    style={{
                      color: isMenuActive(['/dashboard/connectors', '/dashboard/manageusers', '/dashboard/data_management', 'datastatus']) ? 'white' : 'black',
                      fontWeight: 'bold',
                      textTransform: 'capitalize'
                    }}
                    onClick={(event) => handleSubMenuClick(event, 'admin')}
                    variant={isMenuActive(['/dashboard/connectors', '/dashboard/manageusers', '/dashboard/data_management', 'datastatus']) ? "contained" : "text"}
                  >
                    Admin
                    <ExpandMoreIcon />
                  </Button>
                  <Menu
                    anchorEl={subMenuAnchorEl.admin}
                    open={Boolean(subMenuAnchorEl.admin)}
                    onClose={() => handleSubMenuClose('admin')}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <MenuItem onClick={handleSubMenuClose}>
                      <Link to="/dashboard/connectors" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                        Connectors
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleSubMenuClose}>
                      <Link to="/dashboard/manageusers" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                        Manage Users
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleSubMenuClose}>
                      <Link to="/dashboard/data_management" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                        Data Management
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleSubMenuClose}>
                      <Link to="/dashboard/datastatus" style={{ textDecoration: 'none', color: 'black' }} onClick={handleMenuClose}>
                        Data Summary
                      </Link>
                    </MenuItem>
                  </Menu>
                </>
                :
                ''
            }
          </Box>
          <AccountPopover UserData={user} />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;