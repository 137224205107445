import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../constants/constants";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { PieChart, Pie, Cell, Legend as PieLegend, ResponsiveContainer as PieResponsiveContainer } from "recharts";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Charts = ({ selectedTenants, timeRange }) => {
  const [alertsChartData, setAlertsChartData] = useState([]);
  const [vulnerabilityChartData, setVulnerabilityChartData] = useState([]);
  const [cisChartData, setCisChartData] = useState([]);
  const [bruteforceChartData, setBruteforceChartData] = useState([]);
  const [threatIntelData, setThreatIntelData] = useState([]);
  const [threatSeverityChartData, setThreatSeverityChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details?.data?.accessToken;

  const calculateTimeRange = () => {
    const now = new Date();
    let startTime;

    switch (timeRange) {
      case "24h":
        startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "48h":
        startTime = new Date(now.getTime() - 48 * 60 * 60 * 1000);
        break;
      case "7d":
        startTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      default:
        startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    }

    return {
      startTime: startTime.toISOString(),
      endTime: now.toISOString(),
    };
  };

  useEffect(() => {
    if (!selectedTenants.length) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const { startTime, endTime } = calculateTimeRange();

        const data = JSON.stringify({
          tenantNames: selectedTenants,
          startTime: startTime,
          endTime: endTime,
        });

        const config = {
          method: "post",
          url: `${BaseUrl}/user/soc_dash_data`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        const response = await axios(config);
        const alertsCountData = response.data.alertsCountData;
        const vulnerabilityCountData = response.data.vulnerabilityCountData;
        const cisCountData = response.data.cisCountData;
        const bruteforceCountData = response.data.bruteforceCountData;

        const alertsData = Object.keys(alertsCountData).map((tenant) => ({
          tenant,
          info: alertsCountData[tenant].info,
          low: alertsCountData[tenant].low,
          medium: alertsCountData[tenant].medium,
          high: alertsCountData[tenant].high,
          critical: alertsCountData[tenant].critical,
        }));

        const vulnerabilityData = Object.keys(vulnerabilityCountData).map((tenant) => ({
          name: tenant,
          value: vulnerabilityCountData[tenant],
        }));

        const cisData = Object.keys(cisCountData).map((tenant) => ({
          name: tenant,
          value: cisCountData[tenant],
        }));

        const bruteforceData = Object.keys(bruteforceCountData).map((tenant) => ({
          name: tenant,
          value: bruteforceCountData[tenant],
        }));

        const threatIntel = response.data.threatIntelData;
        
        const threatIntelFormattedData = Object.keys(threatIntel.maliciousipCountData).map((tenant) => ({
          id: tenant,
          tenant,
          maliciousipCount: threatIntel.maliciousipCountData[tenant] || 0,
          maliciousFileCount: threatIntel.maliciousFileCountData[tenant] || 0,
          maliciousURLCount: threatIntel.maliciousURLCountData[tenant] || 0,
          maliciousLINKCount: threatIntel.maliciousLINKCountData[tenant] || 0,
          maliciousDomainCount: threatIntel.maliciousDomainCountData[tenant] || 0,
          totalThreatCount: threatIntel.totalThreatCountData[tenant] || 0,
        }));

        const threatSeverityCountData = response.data.threatIntelData.threatSeverityCountData;
        const threatSeverityData = Object.keys(threatSeverityCountData).map((tenant) => ({
          tenant,
          undefined: threatSeverityCountData[tenant].undefined || 0,
          medium: threatSeverityCountData[tenant].medium || 0,
          high: threatSeverityCountData[tenant].high || 0,
          low: threatSeverityCountData[tenant].low || 0,
        }));

        setAlertsChartData(alertsData);
        setVulnerabilityChartData(vulnerabilityData);
        setCisChartData(cisData);
        setBruteforceChartData(bruteforceData);
        setThreatIntelData(threatIntelFormattedData);
        setThreatSeverityChartData(threatSeverityData);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedTenants, timeRange, token]);

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center", 
          height: "100vh" 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  const pieColors = [ "#67a353", "#b2911c", "#00bfa0", "#cc5f21", "#8c8c94", "#08aad2", "#ba2e0f",
    "#f89256", "#d63f38", "#a1887f"
  ];

  const renderPieChart = (data, chartName) => {
    if (data.length === 0) {
      return <Typography variant="h6" color="textSecondary">Data not available in the selected time range</Typography>;
    }
    return (
      <PieResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius="80%"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
            ))}
          </Pie>
          <PieLegend />
        </PieChart>
      </PieResponsiveContainer>
    );
  };

  const renderThreatSeverityChart = (data) => {
    if (data.length === 0) {
      return <Typography variant="h6" color="textSecondary">No data available for Threat Severity</Typography>;
    }
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="tenant" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="undefined" stackId="a" fill="#08aad2" />
          <Bar dataKey="medium" stackId="a" fill="#dfb52c" />
          <Bar dataKey="high" stackId="a" fill="#cc5f21" />
          <Bar dataKey="low" stackId="a" fill="#69ae34" />
        </BarChart>
      </ResponsiveContainer>
    );
  };
  
  const columns = [
    { field: "tenant", headerName: "Tenant", flex: 1, cellClassName: 'word-wrap' },
    { field: "maliciousipCount", headerName: "Malicious IP", flex: 1 },
    { field: "maliciousFileCount", headerName: "Malicious File", flex: 1 },
    { field: "maliciousURLCount", headerName: "Malicious URL", flex: 1 },
    { field: "maliciousLINKCount", headerName: "Malicious Link", flex: 1 },
    { field: "maliciousDomainCount", headerName: "Malicious Domain", flex: 1 },
    { field: "totalThreatCount", headerName: "Total Threat", flex: 1 },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: -15,
                  left: 16,
                  backgroundColor: "#fff",
                  padding: "0 12px",
                  fontWeight: "bold",
                  color: "#555",
                  zIndex: 1,
                }}
              >
                Alerts Count
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={alertsChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tenant" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="critical" stackId="a" fill="#d63f38" />
                  <Bar dataKey="high" stackId="a" fill="#cc5f21" />
                  <Bar dataKey="medium" stackId="a" fill="#dfb52c" />
                  <Bar dataKey="low" stackId="a" fill="#69ae34" />
                  <Bar dataKey="info" stackId="a" fill="#08aad2" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: -15,
                  left: 16,
                  backgroundColor: "#fff",
                  padding: "0 12px",
                  fontWeight: "bold",
                  color: "#555",
                  zIndex: 1,
                }}
              >
                Vulnerable Devices
              </Typography>
              {renderPieChart(vulnerabilityChartData, "Vulnerability")}
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: -15,
                  left: 16,
                  backgroundColor: "#fff",
                  padding: "0 12px",
                  fontWeight: "bold",
                  color: "#555",
                  zIndex: 1,
                }}
              >
                Devices needing Hardening (CIS)
              </Typography>
              {renderPieChart(cisChartData, "CIS")}
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative" }}>
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: -15,
                  left: 16,
                  backgroundColor: "#fff",
                  padding: "0 12px",
                  fontWeight: "bold",
                  color: "#555",
                  zIndex: 1,
                }}
              >
                Bruteforce Count
              </Typography>
              {renderPieChart(bruteforceChartData, "Bruteforce")}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <br /><br />
      <Box>
  <Grid container spacing={3}>
    <Grid item xs={12} md={8}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative", height: 400 }}>
        <Typography variant="h6" sx={{ position: "absolute", top: -15, left: 16, backgroundColor: "#fff", padding: "0 12px", fontWeight: "bold", color: "#555", zIndex: 1 }}>
          Threat Intelligence Data
        </Typography>
        <div style={{ height: "100%", width: "100%" }}>
        <style>
        {`
          .MuiDataGrid-columnHeader {
            background-color: green;
            color: white;
          }
          .MuiDataGrid-cell {
            color: black;
          }
          .MuiDataGrid-footerContainer {
            background-color: lightgray;
          }
          .MuiDataGrid-row:nth-of-type(odd) {
            background-color: lightyellow;
          }
          .MuiDataGrid-row:nth-of-type(even) {
            background-color: white;
          }
        `}
      </style>
          <DataGrid rows={threatIntelData} columns={columns} pageSize={5} />
        </div>
      </Paper>
    </Grid>

    <Grid item xs={12} md={4}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: "8px", position: "relative", height: 400 }}>
        <Typography variant="h6" sx={{ position: "absolute", top: -15, left: 16, backgroundColor: "#fff", padding: "0 12px", fontWeight: "bold", color: "#555", zIndex: 1 }}>
          Threat Severity Count
        </Typography>
        <div style={{ height: "100%", width: "100%", marginTop: '8%' }}>
          {renderThreatSeverityChart(threatSeverityChartData)}
        </div>
      </Paper>
    </Grid>
  </Grid>
</Box>
<br />
    </>
  );
};

export default Charts;