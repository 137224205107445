import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import BarChartIcon from "@mui/icons-material/BarChart";
import { BaseUrl } from "../constants/constants";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ArchiveIcon from '@mui/icons-material/Archive';
import { getTenantDetails } from '../service/TenantDetails';

ChartJS.register(ArcElement, Tooltip, Legend);

const DataStatus = () => {
  const [agentStatusData, setAgentStatusData] = useState({ active: 0, disconnected: 0 });
  const [syslogStatusData, setSyslogStatusData] = useState({ active: 0, disconnected: 0 });
  const [totalSizeGB, setTotalSizeGB] = useState(0);
  const [agentDevices, setAgentDevices] = useState([]);
  const [syslogDevices, setSyslogDevices] = useState([]);
  const [filteredAgentDevices, setFilteredAgentDevices] = useState([]);
  const [filteredSyslogDevices, setFilteredSyslogDevices] = useState([]);
  const [currentFilteredIP, setCurrentFilteredIP] = useState(null);
  const [agentSearchQuery, setAgentSearchQuery] = useState("");
  const [syslogSearchQuery, setSyslogSearchQuery] = useState("");

  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = user_details?.data?.accessToken;

  const fetchData = async () => {
    try {
      const config = {
        method: "get",
        url: `${BaseUrl}/tenant/device_details`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);

      const agentDevices = response.data?.agent_device_details?.data?.affected_items || [];
      const formattedAgentDevices = agentDevices.map((device, index) => ({
        id: device.id || index,
        ...device,
      }));
      setAgentDevices(formattedAgentDevices);
      setFilteredAgentDevices(formattedAgentDevices);

      const syslogDeviceList = response.data?.syslog_device_list || [];
      const formattedSyslogDevices = syslogDeviceList.map((device, index) => ({
        id: device.dev_id || index,
        ...device,
      }));
      setSyslogDevices(formattedSyslogDevices);
      setFilteredSyslogDevices(formattedSyslogDevices);

      const activeAgents = agentDevices.filter((device) => device.status === "active").length;
      const disconnectedAgents = agentDevices.filter((device) => device.status === "disconnected").length;
      setAgentStatusData({ active: activeAgents, disconnected: disconnectedAgents });

      const activeSyslogDevices = response.data?.total_data?.active_syslog_devices || [];
      setSyslogStatusData({ active: activeSyslogDevices.length, ips: activeSyslogDevices });

      const totalSize = response.data?.total_data?.total || {};
      setTotalSizeGB(totalSize.sizeInGB || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [tenantDetails, setTenantDetails] = useState();
  console.log(tenantDetails);
  useEffect(() => {
    getTenantDetails()
      .then((resp) => {
        setTenantDetails(resp);
      });
  }, []);

  useEffect(() => {
    setFilteredAgentDevices(
      agentDevices.filter((device) =>
        device.name.toLowerCase().includes(agentSearchQuery.toLowerCase())
      )
    );
  }, [agentSearchQuery, agentDevices]);

  useEffect(() => {
    setFilteredSyslogDevices(
      syslogDevices.filter((device) =>
        device.device_name.toLowerCase().includes(syslogSearchQuery.toLowerCase())
      )
    );
  }, [syslogSearchQuery, syslogDevices]);

  const handleLegendClick = (index) => {
    if (index === 0) {
      if (currentFilteredIP === "active") {
        setFilteredAgentDevices(agentDevices);
        setCurrentFilteredIP(null);
      } else {
        setFilteredAgentDevices(agentDevices.filter((device) => device.status === "active"));
        setCurrentFilteredIP("active");
      }
    } else if (index === 1) {
      if (currentFilteredIP === "disconnected") {
        setFilteredAgentDevices(agentDevices);
        setCurrentFilteredIP(null);
      } else {
        setFilteredAgentDevices(agentDevices.filter((device) => device.status === "disconnected"));
        setCurrentFilteredIP("disconnected");
      }
    } else {
      setFilteredAgentDevices(agentDevices);
      setCurrentFilteredIP(null);
    }
  };

  const handlePieChartClick = (event, elements, chartType) => {
    if (elements.length > 0) {
      const index = elements[0].index; // Get the index of the clicked segment

      if (chartType === "agent") {
        // Toggle filtering for Agent Devices
        const selectedStatus = index === 0 ? "active" : "disconnected";
        if (currentFilteredIP === selectedStatus) {
          setFilteredAgentDevices(agentDevices); // Reset to show all devices
          setCurrentFilteredIP(null);
        } else {
          setFilteredAgentDevices(
            agentDevices.filter((device) => device.status === selectedStatus)
          );
          setCurrentFilteredIP(selectedStatus);
        }
      } else if (chartType === "syslog") {
        // Toggle filtering for Syslog Devices
        const clickedIP = syslogStatusData.ips[index];
        if (currentFilteredIP === clickedIP) {
          setFilteredSyslogDevices(syslogDevices); // Reset to show all devices
          setCurrentFilteredIP(null);
        } else {
          setFilteredSyslogDevices(
            syslogDevices.filter((device) => device.ip_address === clickedIP)
          );
          setCurrentFilteredIP(clickedIP);
        }
      }
    }
  };

  const resetAgentFilters = () => {
    setAgentSearchQuery("");
    setFilteredAgentDevices(agentDevices);
    setCurrentFilteredIP(null);
  };

  const resetSyslogFilters = () => {
    setSyslogSearchQuery("");
    setFilteredSyslogDevices(syslogDevices);
    setCurrentFilteredIP(null);
  };


  const agentChartData = {
    labels: [
      `Active (${agentStatusData.active})`,
      `Disconnected (${agentStatusData.disconnected})`,
    ],
    datasets: [
      {
        data: [agentStatusData.active, agentStatusData.disconnected],
        backgroundColor: ["#4caf50", "#f44336"],
        hoverBackgroundColor: ["#45a049", "#e53935"],
      },
    ],
  };

  const syslogChartData = {
    labels: syslogStatusData.ips?.map((ip) => `${ip}`) || [],
    datasets: [
      {
        data: syslogStatusData.ips?.map(() => 1) || [],
        backgroundColor: syslogStatusData.ips?.map(() => "#2196F3") || [],
        hoverBackgroundColor: syslogStatusData.ips?.map(() => "#1976D2") || [],
      },
    ],
  };

  const agentColumns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "ip", headerName: "IP", width: 200 },
    { field: "status", headerName: "Status", width: 150 },
  ];

  const syslogColumns = [
    { field: "ip_address", headerName: "IP Address", width: 200 },
    { field: "device_name", headerName: "Device Name", width: 200 },
    { field: "device_type", headerName: "Device Type", width: 150 },
    { field: "device_option", headerName: "Device Option", width: 200 },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Inactive"),
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h4"
        align="left"
        sx={{
          color: "#4caf50",
          fontWeight: "bold",
          marginBottom: 2,
          fontSize: "24px",
        }}
      >
        Data Status Dashboard
      </Typography>
      <Box sx={{ display: "flex", gap: 3, justifyContent: "space-between", marginBottom: 4 }}>
  {/* Total Card */}
  <Card
    sx={{
      flex: "1",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: 2,
      padding: 2,
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "left" }}>
          Total
        </Typography>
        <BarChartIcon sx={{ color: "#4caf50" }} />
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#4caf50",
          fontWeight: "bold",
          marginTop: 1,
          textAlign: "left",
        }}
      >
        {totalSizeGB} GB
      </Typography>
    </CardContent>
  </Card>

  {/* Live Retention Days Card */}
  <Card
    sx={{
      flex: "1",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: 2,
      padding: 2,
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "left" }}>
          Live Retention Days
        </Typography>
        <LiveTvIcon sx={{ color: "#4caf50" }} />
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#4caf50",
          fontWeight: "bold",
          marginTop: 1,
          textAlign: "left",
        }}
      >
        30 Days
      </Typography>
    </CardContent>
  </Card>

  {/* Archive Retention Days Card */}
  <Card
    sx={{
      flex: "1",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: 2,
      padding: 2,
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "left" }}>
          Archive Retention Days
        </Typography>
        <ArchiveIcon sx={{ color: "#4caf50" }} />
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#4caf50",
          fontWeight: "bold",
          marginTop: 1,
          textAlign: "left",
        }}
      >
        90 Days
      </Typography>
    </CardContent>
  </Card>
</Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {/* Agent Devices Section */}
        <Box sx={{ display: "flex", gap: 3, alignItems: "stretch" }}>
          <Card
            sx={{
              flex: "0 0 25%",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={resetAgentFilters} // Add reset function
              variant="contained"
              color="primary"
              sx={{
                position: "absolute",
                top: 10, // Place it 10px from the top of the container
                right: 10, // Align it to the right side
                zIndex: 2, // Ensure it's on top of other elements
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Reset Filters
            </Button>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                top: "10px",
                left: "10px",
                fontWeight: "bold",
                color: "black",
                zIndex: 1,
                fontSize: "16px",
              }}
            >
              Agent Status
            </Typography>
            <Pie
              data={agentChartData}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    onClick: (_, legendItem) => handleLegendClick(legendItem.index),
                    labels: {
                      usePointStyle: true,
                      padding: 5,
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const label = context.label.split(" (")[0];
                        const value = context.raw;
                        return `${label}: ${value}`;
                      },
                    },
                  },
                },
                cutout: "50%",
                responsive: true,
                maintainAspectRatio: false,
                onClick: (event, elements) => handlePieChartClick(event, elements, "agent"), // Add chartType here
              }}
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />

          </Card>

          <Card
            sx={{
              flex: "0 0 70%",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              padding: 2,
              overflow: "hidden",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "16px" }}>
                Agent Device Details
              </Typography>
              <TextField
                label="Search Agent Devices"
                variant="outlined"
                size="small"
                value={agentSearchQuery}
                onChange={(e) => setAgentSearchQuery(e.target.value)}
                sx={{ width: "300px" }}
              />
            </Box>
            <div style={{ height: "360px" }}> {/* Increased height to show all rows */}
              <DataGrid
                rows={filteredAgentDevices}
                columns={agentColumns.map((column) => ({ ...column, flex: 1 }))}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
                autoHeight={false}
                sx={{
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "green",
                    color: "white",
                    fontSize: "14px",
                  },
                  "& .MuiDataGrid-cell": {
                    color: "black",
                    fontSize: "14px",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: "lightgray",
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                    backgroundColor: "lightyellow",
                  },
                  "& .MuiDataGrid-row:nth-of-type(even)": {
                    backgroundColor: "white",
                  },
                }}
              />
            </div>
          </Card>
        </Box>

        {/* Syslog Devices Section */}
        <Box sx={{ display: "flex", gap: 3, alignItems: "stretch" }}>
          <Card
            sx={{
              flex: "0 0 25%",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative", // To position the button above the chart
            }}
          >
            <Typography
              variant="h5"
              sx={{
                position: "absolute",
                top: "10px",
                left: "10px",
                fontWeight: "bold",
                color: "black",
                zIndex: 1,
                fontSize: "16px",
              }}
            >
              Live Data Ingestion from Network Devices
            </Typography>

            {/* Reset Button for Syslog */}
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                top: "43px",
                right: "10px",
                zIndex: 2,
                padding: "5px 10px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
              onClick={resetSyslogFilters}
            >
              Reset Filters
            </Button>

            <Pie
              data={syslogChartData}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    onClick: (event, legendItem) =>
                      handlePieChartClick(event, [], "syslog"),
                    labels: {
                      usePointStyle: true,
                      padding: 20,
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const label = context.label.split(" (")[0];
                        return `${label}`;
                      },
                    },
                  },
                },
                cutout: "50%",
                responsive: true,
                maintainAspectRatio: false,
                onClick: (event, elements) => handlePieChartClick(event, elements, "syslog"),
              }}
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </Card>

          <Card
            sx={{
              flex: "0 0 70%",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              padding: 2,
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "16px" }}>
                Network Device Details
              </Typography>
              <TextField
                label="Search Syslog Devices"
                variant="outlined"
                size="small"
                value={syslogSearchQuery}
                onChange={(e) => setSyslogSearchQuery(e.target.value)}
                sx={{ width: "300px" }}
              />
            </Box>
            <div style={{ height: "360px" }}> {/* Increased height to show all rows */}
              <DataGrid
                rows={filteredSyslogDevices}
                columns={syslogColumns.map((column) => ({ ...column, flex: 1 }))}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
                autoHeight={true} // Enable dynamic height
                sx={{
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "green",
                    color: "white",
                    fontSize: "14px",
                  },
                  "& .MuiDataGrid-cell": {
                    color: "black",
                    fontSize: "14px",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: "lightgray",
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                    backgroundColor: "lightyellow",
                  },
                  "& .MuiDataGrid-row:nth-of-type(even)": {
                    backgroundColor: "white",
                  },
                }}
              />

            </div>

          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default DataStatus;