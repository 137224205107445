import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import SourceIcon from '@mui/icons-material/Source';
import Navbar from './Navbar';
import { Card, Container, Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ActiveResponse, BaseUrl, ListAlerts, TenantDetails } from '../constants/constants';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const columns = [
  { field: 'timestamp', headerName: 'Scan Time', flex: 1.1 },
  { field: 'agent_name', headerName: 'Agent Name', flex: 1 },
  { field: 'agent_ip', headerName: 'Agent IP', flex: 0.8 },
  {
    field: 'sca_data', headerName: 'CIS Data', flex: 1.5, align: 'center', headerAlign: 'center',
    renderCell: (params) => (
      <Stack spacing={1} alignItems="center">
        <Stack direction="row" spacing={1}>
          <Chip label={`Invalid: ${params.row.sca_data.invalid}`} color="primary" size="small" />
          <Chip label={`Passed: ${params.row.sca_data.passed}`} color="success" size="small" />
          <Chip label={`Failed: ${params.row.sca_data.failed}`} color="error" size="small" />
        </Stack>
      </Stack>
    ),
  },
  { field: 'total_checks', headerName: 'Total Checks', flex: 0.5 },
  { field: 'score', headerName: 'Score', flex: 0.5 },
];

export default function ServerPaginationGrid() {
  const tenant_access_switched = localStorage.getItem("tenant_access_switched");
    
  const user_details = JSON.parse(window.localStorage.getItem("user_details"));
  const token = tenant_access_switched ? tenant_access_switched : user_details.data.accessToken;

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [agentNames, setAgentNames] = React.useState([]);
  const [selectedAgent, setSelectedAgent] = React.useState('');
  const [agentIPs, setAgentIPs] = React.useState([]);
  const [selectedAgentIP, setSelectedAgentIP] = React.useState('');
  const now = new Date().toISOString();
  const [startdate, setStartDate] = React.useState(dayjs(new Date(Date.now() - 24 * 60 * 60 * 1000)));
  const [enddate, setEndDate] = React.useState(dayjs(now));

  const getTodayDateRange = () => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    return { start, end };
  };

  React.useEffect(() => {
    const { start, end } = getTodayDateRange();
    fetchData(0, start, end);
  }, []);

  const fetchData = async (page, startDate = null, endDate = null) => {
    setLoading(true);

    const dateFilter = startDate && endDate ? {
      range: {
        timestamp: {
          gte: startDate.toISOString(),
          lte: endDate.toISOString(),
        },
      },
    } : null;

    const queryFilters = [
      { match_all: {} },
      { match_phrase: { location: "sca" } },
      { match_phrase: { "data.sca.type": "summary" } },
    ];

    if (dateFilter) {
      queryFilters.push(dateFilter);
    }

    const config = {
      method: 'post',
      url: BaseUrl + ListAlerts + page,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data: {
        size: 500,
        sort: [{ timestamp: "desc" }],
        _source: ["id", "timestamp", "agent.ip", "agent.name", "agent.id", "data.sca.score", "data.sca.total_checks", "data.sca.policy_id", "data.sca.invalid", "data.sca.description", "data.sca.passed", "data.sca.failed", "data.sca.policy"],
        query: {
          bool: {
            filter: queryFilters,
          },
        },
      },
    };

    try {
      const response = await axios(config);
      const hits = response.data.hits.hits.map(element => {
        const timestamp = new Date(element._source.timestamp).toLocaleString();
        return {
          id: element._source.id,
          timestamp: timestamp,
          agent_ip: element._source.agent.ip,
          agent_name: element._source.agent.name,
          agent_id: element._source.agent.id,
          score: element._source.data.sca.score,
          total_checks: element._source.data.sca.total_checks,
          policy_id: element._source.data.sca.policy_id,
          invalid: element._source.data.sca.invalid,
          description: element._source.data.sca.description,
          passed: element._source.data.sca.passed,
          failed: element._source.data.sca.failed,
          policy: element._source.data.sca.policy,
          sca_data: {
            passed: element._source.data.sca.passed,
            failed: element._source.data.sca.failed,
            invalid: element._source.data.sca.invalid,
          },
        };
      });

      setData(hits.sort((a, b) => b.score - a.score));
      setOriginalData(hits);
      setTotalRows(response.data.hits.total.value);

      const uniqueAgentNames = [...new Set(hits.map(item => item.agent_name))];
      setAgentNames(uniqueAgentNames);

      const uniqueAgentIPs = [...new Set(hits.map(item => item.agent_ip))];
      setAgentIPs(uniqueAgentIPs);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleAgentChange = (event) => {
    const agentName = event.target.value;
    setSelectedAgent(agentName);
    filterData(agentName, selectedAgentIP);
  };

  const handleAgentIPChange = (event) => {
    const agentIP = event.target.value;
    setSelectedAgentIP(agentIP);
    filterData(selectedAgent, agentIP);
  };

  const filterData = (agentName, agentIP) => {
    let filteredData = originalData;

    if (agentName) {
      filteredData = filteredData.filter(item => item.agent_name === agentName);
    }
    if (agentIP) {
      filteredData = filteredData.filter(item => item.agent_ip === agentIP);
    }

    setData(filteredData);
    setTotalRows(filteredData.length);
  };

  const updateDate = () => {
    fetchData(0, startdate, enddate);
  };
  
  const resetDate = () => {
    const { start, end } = getTodayDateRange();
    setStartDate(dayjs(start));
    setEndDate(dayjs(end));
    setSelectedAgent('');
    setSelectedAgentIP('');
    fetchData(0, start, end);
  };

  return (
    <>
      <Card>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <br />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControl sx={{ m: 1, width: 250 }} size={'small'}>
                <InputLabel id="agent-ip-select-label">Agent IP</InputLabel>
                <Select
                  labelId="agent-ip-select-label"
                  id="agent-ip-select"
                  value={selectedAgentIP}
                  label="Agent IP"
                  onChange={handleAgentIPChange}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {agentIPs.map((agentIP) => (
                    <MenuItem key={agentIP} value={agentIP}>{agentIP}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, width: 250 }} size={'small'}>
                <InputLabel id="agent-select-label">Agent Name</InputLabel>
                <Select
                  labelId="agent-select-label"
                  id="agent-select"
                  value={selectedAgent}
                  label="Agent Name"
                  onChange={handleAgentChange}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {agentNames.map((agentName) => (
                    <MenuItem key={agentName} value={agentName}>{agentName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} size="small" />}
                  label="Start Date"
                  value={startdate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} size="small" />}
                  label="End Date"
                  value={enddate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
              <Button variant="contained" onClick={updateDate} size="small">
                Apply
              </Button>
              <Button variant="contained" onClick={resetDate} size="small">
                Reset
              </Button>
            </Box>
            <div style={{ height: 440, width: '100%' }}>
              <style>
              {`
                .MuiDataGrid-columnHeader {
                  background-color: green;
                  color: white;
                }
                .MuiDataGrid-cell {
                  color: black;
                }
                .MuiDataGrid-footerContainer {
                  background-color: lightgray;
                }
                .MuiDataGrid-row:nth-of-type(odd) {
                  background-color: lightyellow;
                }
                .MuiDataGrid-row:nth-of-type(even) {
                  background-color: white;
                }
              `}
              </style>
              <DataGrid
                rows={data}
                rowCount={totalRows}
                loading={loading}
                rowsPerPageOptions={[10]}
                pagination
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                columns={columns}
              />
            </div>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
}